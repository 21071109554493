import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  List,
  ListItemButton,
  Collapse,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import "./NavItems.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NavItems = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
    setOpen3(false);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("TenX_50_Deposit_Bonus.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "TenX_50_Deposit_Bonus.pdf";
        alink.click();
      });
    });
  };

  return (
    <>
      <div
        className="NC-Logo3"
        onClick={() => {
          navigate("/");
        }}
      ></div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: "70px",
        }}
      >
        <Box>
          <List>
            <Link to="/about" className="Navitem-link">
              <ListItemButton sx={{ borderRadius: "15px" }}>
                <Typography fontSize="1.4rem" variant="p">
                  About
                </Typography>
              </ListItemButton>
            </Link>
            <ListItemButton
              sx={{
                borderRadius: "15px",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={handleClick2}
            >
              <Typography fontSize="1.4rem" variant="p">
                Market
              </Typography>
              {open2 ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/market" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.2rem" variant="p">
                      Main Market
                    </Typography>
                  </ListItemButton>
                </Link>
                <Link to="/marketEco" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.2rem" variant="p">
                      Economic Calendar
                    </Typography>
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
            <ListItemButton
              onClick={handleClick1}
              sx={{
                borderRadius: "15px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize="1.4rem" variant="p">
                Trading Service
              </Typography>
              {open1 ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/FinIn" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }} onClick={handleClick3}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.2rem" variant="p">
                      Financial Instruments
                    </Typography>
                  </ListItemButton>
                </Link>
                <Link to="/crypto" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>

                    <Typography fontSize="1.1rem" variant="p">
                      Cryptocurrencies
                    </Typography>
                  </ListItemButton>
                </Link>

                <Link to="/TsAccount" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.1rem" variant="p">
                      Account Type
                    </Typography>
                  </ListItemButton>
                </Link>
                <Link to="/TsDepoWit" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.1rem" variant="p">
                      Deposit & Withdrawal
                    </Typography>
                  </ListItemButton>
                </Link>
                <Link to="/TsMt4" className="Navitem-link">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <Typography fontSize="1.1rem" variant="p">
                      MetaTrader 4
                    </Typography>
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>

            <ListItemButton
              onClick={onButtonClick}
              sx={{ borderRadius: "15px" }}
            >
              <Typography fontSize="1.4rem" variant="p">
                Promotions
              </Typography>
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => navigate("/underconstruction")}
                >
                  <ListItemIcon>
                    <ArrowForwardIosIcon />
                  </ListItemIcon>
                  <ListItemText primary="Coming Soon" />
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          <Divider />
          <Box>
            <List>
              <Link to="/about" className="Navitem-link">
                <ListItemButton>
                  <Typography
                    color="grey"
                    fontSize="1.3rem"
                    variant="p"
                    fontWeight="600"
                  >
                    About Us
                  </Typography>
                </ListItemButton>
              </Link>
              <Link to="/underconstruction" className="Navitem-link">
                <ListItemButton onClick={() => navigate("/underconstruction")}>
                  <Typography
                    color="grey"
                    fontSize="1.3rem"
                    variant="p"
                    fontWeight="600"
                  >
                    Company News
                  </Typography>
                </ListItemButton>
              </Link>
              <Link to="/underconstruction" className="Navitem-link">
                <ListItemButton>
                  <Typography
                    color="grey"
                    fontSize="1.3rem"
                    variant="p"
                    fontWeight="600"
                  >
                    FAQ
                  </Typography>
                </ListItemButton>
              </Link>
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "10px",
            }}
          >
            <a
              href="https://client.tenxprime.com/register"
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer noopener"
            >
              <Button variant="contained" color="primary" sx={{ p: "15px" }}>
                <Typography color="white" variant="p">
                  Open Trading Account
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
        <Typography
          variant="p"
          sx={{
            color: "black",
            textAlign: "center",
            height: "20px",
          }}
        >
          {"Copyright © "}
          TenX Prime Limited
        </Typography>
      </Box>
    </>
  );
};

export default NavItems;
