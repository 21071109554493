import "./NegativeBalance.css";
import { Typography, Container, Box, Paper, Stack } from "@mui/material";
import deposit from "../../resource/fiftyDeposit.svg";
import shield from "../../resource/SecurityOfFunds.svg";
const NegativeBalance = () => {
  return (
    <>
      <Paper elevation={13}>
        <Stack
          sx={{
            backgroundColor: "#0068ff",
            maxHeight: "100%",
            height: "100%",
          }}
          className="Home-Stack-Deposit"
        >
          <div className="H-paper">
            <Container>
              <Box className="HW-Box">
                <Box
                  className="H-50"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2vh",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img src={deposit} alt="deposit" height="150px" />

                  <Typography
                    color="orange"
                    variant="h5"
                    fontWeight="600"
                    style={{ fontSize: "2.5em" }}
                    textAlign="center"
                    lineHeight="2.5rem"
                  >
                    50% Deposit <br /> Bonus
                  </Typography>

                  <Typography
                    fontSize="1.2rem"
                    variant="body1"
                    color="white"
                    align="center"
                    sx={{ px: "25px" }}
                  >
                    Forex traders are always looking to trade on a higher margin
                    but they can't afford to bear the risk. With TenX Prime, you
                    can get 50% bonus on deposits that enables you to trade on a
                    higher margin.
                  </Typography>
                </Box>

                <Box className="H-Negative">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={shield} alt="shield" height="150px" />
                  </Box>

                  <Typography
                    color="orange"
                    variant="h5"
                    fontWeight="600"
                    style={{ fontSize: "2.5em" }}
                    textAlign="center"
                    lineHeight="2.5rem"
                  >
                    Negative Balance <br /> Security
                  </Typography>

                  <Typography
                    fontSize="1.2rem"
                    variant="body1"
                    color="white"
                    align="center"
                    sx={{ px: "25px" }}
                  >
                    TenX Prime ensures the protection of trader's negative
                    account balance by compenstating it to zero.
                  </Typography>
                  <Box className="H-N-Button"></Box>
                </Box>
              </Box>
            </Container>
          </div>
        </Stack>
      </Paper>
    </>
  );
};

export default NegativeBalance;
