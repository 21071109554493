import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Crypto from "./pages/crypto";
import FinIn from "./pages/finIn";
import Market from "./pages/market";
import MarketEco from "./pages/marketEco";
import TsDepoWit from "./pages/TsDepoWit";
import TsAccount from "./pages/TsAccount";
import TsMt4 from "./pages/TsMt4";
import ScrollTop from "./components/functions/ScrollTop";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { appTheme } from "./Global/themes/theme";
function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <ScrollTop />
          {/** Need to add Scroll to top */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Crypto" element={<Crypto />} />
            <Route path="/FinIn" element={<FinIn />} />
            <Route path="/Market" element={<Market />} />
            <Route path="/MarketEco" element={<MarketEco />} />
            <Route path="/TsDepoWit" element={<TsDepoWit />} />
            <Route path="/TsAccount" element={<TsAccount />} />
            <Route path="/TsMt4" element={<TsMt4 />} />
            {/** Under Construction Page Here */}
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
