import { useState, useEffect } from "react";
import "./LiveQuotes.css";
import {
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import Coin from "./components/Coin";

const LiveQuotes = () => {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    )
      .then((res) => res.json())
      .then((data) => {
        setCoins(data);
        console.log(data);
      })
      .catch((err) => console.log(err));
  }, [coins, search]);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <>
      <Container maxWidth="lg" sx={{ py: "50px" }}>
        <Container maxWidth="sm">
          <Typography
            variant="h2"
            textAlign="center"
            fontWeight="bold"
            color="orange"
          >
            Live Quotes
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              placeholder="Search your Crypto"
              onChange={handleChange}
            ></TextField>
          </Box>
        </Container>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ py: "20px" }}
          >
            <Grid item xs={6} sm={3} md={3}>
              <Typography textAlign="center" fontWeight="800" fontSize="1.6rem">
                Coin
              </Typography>
            </Grid>

            <Grid item xs={5} sm={3} md={3}>
              <Typography textAlign="center" fontWeight="800" fontSize="1.6rem">
                Price MYR
              </Typography>
            </Grid>
            <Grid item xs={0} sm={3} md={3} className="coin-volume">
              <Typography textAlign="center" fontWeight="800" fontSize="1.6rem">
                Volume
              </Typography>
            </Grid>
            <Grid item xs={0} sm={3} md={3} className="coin-percent">
              <Typography textAlign="center" fontWeight="800" fontSize="1.6rem">
                Price change
              </Typography>
            </Grid>
          </Grid>
        </Container>
        {filteredCoins.map((coin) => {
          return (
            <>
              <Coin
                key={coin.id}
                name={coin.name}
                image={coin.image}
                symbol={coin.symbol}
                marketcap={coin.market_cap}
                price={coin.current_price}
                priceChange={coin.price_change_percentage_24h}
                volume={coin.total_volume}
              />
              <Divider />
            </>
          );
        })}
      </Container>
      <Box
        pb="50px"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          href=" https://client.tenxprime.com/register"
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noreferrer noopener"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: "10px", width: "250px" }}
          >
            <Typography
              variant="body1"
              color="white"
              fontWeight="500"
              className="Cry-button"
              sx={{ textTransform: "none", fontSize: "1.2rem" }}
            >
              Trade Crypto
            </Typography>
          </Button>
        </a>
      </Box>
    </>
  );
};

export default LiveQuotes;
