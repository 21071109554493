import "./HeroBanner.css";
import { Grid, Container, Typography, Box } from "@mui/material";

const HeroBanner = () => {
  return (
    <>
      <div className="marketEco-content">
        <Container maxWidth="xl" sx={{ px: "-50px" }} disableGutters={true}>
          <div className="marketEco-banner">
            <Grid container direction="row">
              <Grid item xs={12} md={7}>
                <Box className="marketEco-titles">
                  <Box className="marketEco-title">
                    <Typography
                      variant="h1"
                      color="orange"
                      className="H-title"
                      fontSize={{
                        xs: "2rem",
                        sm: "3rem",
                        md: "3.5rem",
                        xl: "4rem",
                      }}
                      lineHeight={{
                        xs: "2rem",
                        sm: "3rem",
                        md: "3.5rem",
                        xl: "4rem",
                      }}
                      textAlign={{ xs: "center", md: "left" }}
                      sx={{ width: "100%" }}
                    >
                      Economic
                      <br />
                      Calendar
                    </Typography>
                  </Box>
                  <Box className="marketEco-details">
                    <Typography
                      sx={{ width: "100%" }}
                      variant="h6"
                      fontWeight="400"
                      color="white"
                      className="Slide-Description"
                      fontSize={{
                        xs: "1.3rem",
                        sm: "1.8rem",
                        md: "2rem",
                        xl: "2rem",
                      }}
                      lineHeight={{
                        xs: "1.3rem",
                        sm: "1.8rem",
                        md: "2.1rem",
                        xl: "2rem",
                      }}
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      You can stay relevant and updated by checking on the
                      scheduled dates of significant releases or events that may
                      affect the movement of individual security prices or
                      market as a whole.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="marEco-2"></div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
