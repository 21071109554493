import "./finIn.css";
import HeroBanner from "./components/Herobanner";
import UnlimitedAccess from "./components/UnlimitedAccess";
import Checkout from "./components/Checkout";
import ThreeSteps from "./components/ThreeSteps";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";
const finIn = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <UnlimitedAccess />
      {/* <Checkout /> */}
      <ThreeSteps />

      <div>
        <Footer />
      </div>
    </>
  );
};

export default finIn;
