import React from "react";
import { Paper, Box, Grid, Typography, Container } from "@mui/material";
import Deposit from "../../resource/Deposit.svg";
import Shield from "../../resource/SecurityOfFunds.svg";
import check from "../../resource/SecurityofFundCheck.svg";
import { Link } from "react-router-dom";
import "./CardHome.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const CardHome = () => {
  return (
    <>
      <Container maxWidth="lg" sx={{ py: "20px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{
                height: "100%",
                backgroundColor: "#0068ff",
                borderRadius: "20px",
              }}
            >
              <Box className="TC-S" p={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="H-Box-Title-S2"
                >
                  <Typography
                    fontWeight="600"
                    color="white"
                    variant="h5"
                    lineHeight="2.5rem"
                    sx={{
                      width: "100%",
                      textAlign: { xs: "center", md: "center", lg: "left" },
                    }}
                  >
                    Security of <br /> Funds
                  </Typography>
                  <Box className="H-image-Icon">
                    <img src={Shield} alt="deposit" height="100em" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="CB-Item-Content">
                  <img src={check} alt="Check" color="white" height="65rem" />

                  <Typography
                    variant="body1"
                    fontSize="1.3rem"
                    color="white"
                    textAlign="left"
                  >
                    Negative Balance Protection
                  </Typography>
                </Box>
                <Box className="CB-Item-Content">
                  <Box>
                    <img src={check} alt="Check" color="white" height="65rem" />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      fontSize="1.3rem"
                      color="white"
                      fontWeight="400"
                      textAlign="left"
                    >
                      Segregated Account
                    </Typography>
                  </Box>
                </Box>
                <Box className="CB-Item-Content">
                  <img src={check} alt="Check" color="white" height="65rem" />

                  <Typography
                    variant="body1"
                    fontSize="1.3rem"
                    color="white"
                    textAlign="left"
                  >
                    Top Tier Banks Liquidity Pools
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={4}
              sx={{ backgroundColor: "#0068ff", borderRadius: "20px" }}
            >
              <Box p={2} className="TC-S">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="H-Box-Title-S2"
                >
                  <Typography
                    fontWeight="600"
                    color="white"
                    variant="h5"
                    lineHeight="2.5rem"
                    sx={{
                      width: "100%",
                      textAlign: { xs: "center", md: "center", lg: "left" },
                    }}
                  >
                    Deposit &<br /> Withdrawal
                  </Typography>
                  <Box>
                    <img src={Deposit} alt="deposit" height="100em" />
                  </Box>
                </Box>
              </Box>
              <Box px={3}>
                <Typography
                  color="white"
                  variant="body1"
                  fontSize="1.2rem"
                  textAlign="justify"
                >
                  We provide our client with an instant flexible payment and
                  withdrawal method with 0 fees. Why pay more instead we can
                  cover it for you.
                </Typography>
              </Box>
              <Box p={3}>
                <Typography
                  color="white"
                  variant="body1"
                  fontSize="1.2rem"
                  textAlign="justify"
                >
                  Get extra information in our Deposit & Withdrawal section
                </Typography>
              </Box>
              <Box p={3}>
                <Link
                  to="/tradingServiceWitDepo"
                  style={{ textDecoration: "none" }}
                  preventScrollReset
                  className="TC-link-WD"
                >
                  <Typography
                    className="TC-link-WD"
                    variant="body1"
                    fontSize="1.4rem"
                  >
                    Learn More
                  </Typography>
                  <KeyboardDoubleArrowRightIcon />
                </Link>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CardHome;
