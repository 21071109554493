import { Typography, Box, Button } from "@mui/material";
import Metalogo from "../../../resource/metatrade4.png";
import iphone from "../../../resource/phone.png";
import macbook from "../../../resource/laptop.png";

import "./nonmobileuse.css";
const NonmobileUse = () => {
  return (
    <>
      <div className="nonMobileTable">
        <Box className="TableHeader">
          <div className="TS-Iphone">
            <img src={iphone} alt="Iphone" />
          </div>
          <div className="TS-macbook">
            <img src={macbook} alt="macbook" />
          </div>
          <Box>
            <div className="TableHeaderInner">
              <div className="Logo-Account">
                <Box display="flex" gap="1rem">
                  <Typography
                    variant="h3"
                    fontFamily="Mont-HEAVY"
                    textAlign="center"
                    color="orange"
                    fontSize={{
                      xs: "2.5rem",
                      sm: "2.8rem",
                      md: "3rem",
                      lg: "3.8rem",
                      xl: "4rem",
                    }}
                  >
                    TenX{" "}
                  </Typography>
                  <Typography
                    variant="h3"
                    fontFamily="Mont-HEAVYItalic"
                    textAlign="center"
                    color="orange"
                    fontSize={{
                      xs: "2.5rem",
                      sm: "2.8rem",
                      md: "3rem",
                      lg: "3.8rem",
                      xl: "4rem",
                    }}
                  >
                    MT4
                  </Typography>
                </Box>
                <Box
                  pt="20px"
                  pb="35px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={Metalogo} alt="metaTrader4" width="90%" />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: { md: "60%", lg: "50%" } }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "25px",
                      width: { xs: "100%", md: "80%" },
                    }}
                  >
                    <Typography
                      color="white"
                      fontFamily="Mont-Bold"
                      fontSize="1.3rem"
                    >
                      Open TenX Prime
                      <br /> MT4 Standard
                    </Typography>
                  </Button>
                </Box>
              </div>
            </div>
          </Box>
          {/* <div className="ComputerShapes"></div> */}
        </Box>
      </div>
    </>
  );
};

export default NonmobileUse;
