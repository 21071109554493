import "./about.css";
import Hero from "./components/Hero";
import WhyChoose from "./components/WhyChoose";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";
const About = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <Hero />
      <WhyChoose />
      <div>
        <Footer />
      </div>
    </>
  );
};

export default About;
