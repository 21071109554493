import { Container, Grid, Typography, Box } from "@mui/material";
import "./Coin.css";
const Coin = ({ image, name, price, volume, symbol, priceChange }) => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={8} sm={3} md={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={image}
              alt="crypto"
              className="cryptoImage"
              height="35px"
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography fontWeight="bold">{name}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={4} sm={3} md={3}>
          <Typography sx={{ fontSize: { xs: "1rem" } }} className="coin-price">
            {price}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography className="coin-volume" sx={{ fontSize: { xs: "1rem" } }}>
            {volume.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          {priceChange < 0 ? (
            <Typography
              sx={{ fontSize: { xs: "1rem" } }}
              className="coin-percent red"
            >
              {priceChange.toFixed(2)}%
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: { xs: "1rem" } }}
              className="coin-percent green"
            >
              {priceChange.toFixed(2)}%
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Coin;
