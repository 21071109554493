import "./Cards.css";
import { Typography, Grid, Card, Container } from "@mui/material";
const Cards = () => {
  return (
    <>
      <div className="T-MT4-content">
        <Container maxWidth="md" sx={{ display: "flex" }}>
          <Grid
            container
            direction="row"
            alignItems="strech"
            justifyContent="center"
            spacing={2}
          >
            <Grid item md={4} sm={12}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    Flexibility
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    There are several choices of currency pairs to trade in, as
                    well as cryptocurrencies and CFDs.
                  </Typography>
                  <div className="TM-image1"></div>
                </Card>
              </div>
            </Grid>

            <Grid item md={4} sm={12}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    User-Friendly
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    MT4 has a user-friendly interface that is suitable for
                    traders of all levels.
                  </Typography>
                  <div className="TM-image2"></div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    Customisation
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    Develop your own Expert Advisors (EAs) and technical
                    indicators on MetaTrader 4 to match your trading needs and
                    practices. Windows and charts can be arranged for each
                    profile according to your preferences.
                  </Typography>
                  <div className="TM-image3"></div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    Efficiency
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    MT4 doesn’t slow down the performance of your PC or mobile,
                    keeping disruption to a minimum.
                  </Typography>
                  <div className="TM-image4"></div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    Charting Tools
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    Advanced charting tools help analyze the technical aspects
                    of the market.
                  </Typography>
                  <div className="TM-image5"></div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4} sm={12} sx={{ width: "100%" }}>
              <div className="T-MT4-Card">
                <Card sx={{ px: "15px" }} className="T-MT4-Card-details">
                  <Typography
                    color="orange"
                    variant="h4"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{ pt: "5px" }}
                  >
                    Language
                  </Typography>
                  <div className="T-MT4-Divider"></div>
                  <Typography
                    sx={{ py: "30px" }}
                    variant="body1"
                    fontSize="1.2rem"
                  >
                    The platform is available in multiple languages.
                  </Typography>
                  <div className="TM-image6"></div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Cards;
