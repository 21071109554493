import "./MetaTrader.css";
import { Typography, Box, Grid, Container, Button } from "@mui/material";
import appstore from "../../resource/9_3_2_1_App Store.png";
import playstore from "../../resource/9_3_2_2_Google Play.png";
const MetaTrader4 = () => {
  return (
    <>
      <div className="T-MT4-Gadgets">
        <Container maxWidth="lg">
          <Grid container direction="column" gap={3}>
            {/*Grid 1 -- Start */}
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={6}>
                  <div className="TMD-image1"></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    p="10px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h3"
                      textAlign="center"
                      fontWeight="600"
                      color="orange"
                      fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3rem" }}
                    >
                      MetaTrader 4 Desktop
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1.13rem"
                      sx={{
                        pt: "30px",
                        textAlign: { xs: "center", md: "justify" },
                      }}
                    >
                      Highly customizable, the MT4 includes charting tools,
                      market indicators, scripts and EAs, advanced risk
                      management, real time market execution and more.
                    </Typography>
                    <div className="T-Button">
                      <a
                        href="https://download.mql5.com/cdn/web/19741/mt4/tenxprime4setup.exe"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="white"
                            fontWeight="500"
                            sx={{ p: "10px" }}
                            fontSize="1.2rem"
                          >
                            Download Now
                          </Typography>
                        </Button>
                      </a>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/*Grid 1 -- End */}
            {/*Grid 2 -- Start */}
            <Grid item>
              <Grid
                container
                direction="row-reverse"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={6}>
                  <div className="TMD-image2"></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box p="10px">
                    <Typography
                      variant="h3"
                      textAlign="center"
                      fontWeight="600"
                      color="orange"
                      fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3rem" }}
                    >
                      MetaTrader 4 Mobile
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1.13rem"
                      sx={{
                        pt: "30px",
                        textAlign: { xs: "center", md: "justify" },
                      }}
                    >
                      A wide spread trading software is now available for your
                      mobile devices. Enjoy a complete set of trade orders,
                      trading history and interactive charts with MetaTrader 4
                      for mobile. Wherever you are and whatever you do, Forex
                      will always be with you.
                    </Typography>

                    <Box className="TMD-App1">
                      <Box className="TMD-App1-1">
                        <a
                          href="https://download.mql5.com/cdn/mobile/mt4/ios?server=TenXPrime-DEMO,TenXPrime-REAL%20SERVER"
                          target="_blank"
                        >
                          <img src={appstore} alt="App store" width="90%" />
                        </a>
                      </Box>
                      <Box className="TMD-App1-2">
                        <a
                          href="https://download.mql5.com/cdn/mobile/mt4/android?server=TenXPrime-DEMO,TenXPrime-REAL%20SERVER"
                          target="_blank"
                        >
                          <img src={playstore} alt="App store" width="90%" />
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/*Grid 2 -- End */}
            {/*Grid 3 -- Start */}
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={6}>
                  <div className="TMD-image1"></div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box p="10px">
                    <Typography
                      variant="h3"
                      textAlign="center"
                      fontWeight="600"
                      color="orange"
                      fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3rem" }}
                    >
                      MetaTrader 4 Web
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1.13rem"
                      sx={{
                        pt: "30px",
                        textAlign: { xs: "center", md: "justify" },
                      }}
                    >
                      Trade from any browser or any operating system with a
                      well-known interface of a desktop platform. All major
                      tools are available including one click trading and chart
                      trading.
                    </Typography>
                    <div className="T-Button">
                      <a
                        className="MT4Link"
                        href="https://tenxprime.com/webterminal.html"
                        target="_blank"
                      >
                        <Box width="200px">
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              color="white"
                              fontWeight="500"
                              sx={{ p: "10px" }}
                              fontSize="1.2rem"
                            >
                              Log In
                            </Typography>
                          </Button>
                        </Box>
                      </a>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/*Grid 3 -- End */}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default MetaTrader4;
