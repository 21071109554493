import "./TsMt4.css";
import HeroBanner from "./components/HeroBanner";
import Cards from "./components/Cards";
import MetaTrader4 from "./components/MetaTrader";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
const TsMt4 = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <Cards />
      <MetaTrader4 />
      <div>
        <Footer />
      </div>
    </>
  );
};

export default TsMt4;
