import "./UnlimitedAccess.css";
import { Card, Typography, Box, Container } from "@mui/material";
import metalsOil from "../../resource/metalsOil.png";
import currency from "../../resource/currency.png";
import easyFund from "../../resource/easyFund.png";
import high from "../../resource/high.png";
import crypto from "../../resource/crypto.png";
import tightSpread from "../../resource/tightSpread.png";
import superior from "../../resource/superior.png";
const UnlimitedAccess = () => {
  return (
    <>
      <div className="FI-content-Container">
        <Container maxWidth="lg">
          <Box p="15px">
            <Typography
              textAlign="center"
              color="orange"
              variant="h3"
              fontWeight="800"
              className="A-Title"
              sx={{
                fontSize: { xs: "2.3rem", sm: "2.8rem", md: "3.3rem" },
                pt: "30px",
                pb: "10px",
              }}
            >
              Unlimited Access to Global Markets
            </Typography>
            <Container maxWidth="md">
              <Typography fontWeight="400" fontSize="1.2rem" textAlign="center">
                TenX Prime provides entry to the highest level of deep,
                institutional, fully anonymous liquidity with no requotes. Armed
                with the most professional trading environment, TenX Prime
                offers you a range of CFD asset classes, which enables you to
                create opportunities to fully maximize your profits.
              </Typography>
            </Container>
            <Container maxWidth="md">
              <Box
                display="flex"
                flexWrap="wrap"
                gap={3}
                alignItems="stretch"
                justifyContent="center"
                py="20px"
              >
                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={currency} height="70px" alt="currency" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Currency <br />
                      Pairs
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={metalsOil} height="70px" alt="metals&Oil" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Metals & <br />
                      Oils
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={tightSpread} height="70px" alt="tight Spread" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Tight Spread <br />
                      Offering
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={superior} height="70px" alt="Superior" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Superior <br />
                      Trading Tools
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={crypto} height="70px" alt="Crypto" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Crypto <br />
                      Currency
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={high} height="80px" alt="high leverage" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      High <br />
                      Leverage
                    </Typography>
                  </Box>
                </Card>

                <Card
                  elevation={6}
                  sx={{ width: "180px", borderRadius: "10px" }}
                >
                  <Box
                    py="12px"
                    px="10px"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={easyFund} height="70px" alt="easy fund" />
                    <Typography
                      textAlign="center"
                      fontWeight="500"
                      fontSize="1.3rem"
                      pt="10px"
                    >
                      Easy Funding <br />
                      Methods
                    </Typography>
                  </Box>
                </Card>
              </Box>
            </Container>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default UnlimitedAccess;
