import Footer from "../../components/footer";
import Nav from "../../components/navigation/Nav.jsx";

import { Box, Grid, Typography, Divider } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./contact.css";
import GoogleMap from "../contact/resource/googlemap.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
const contact = () => {
  // const location = {
  //   address = ""
  // }
  return (
    <>
      <Nav />
      <div className="Empty-spacing-contact"></div>
      <div className="Contact-Content-Wrapper">
        <div className="Contact-Content-I">
          <Typography
            variant="h1"
            color="white"
            className="H-title"
            fontSize={{
              xs: "2rem",
              sm: "3rem",
              md: "3.3rem",
              xl: "3.8rem",
            }}
            lineHeight={{
              xs: "2rem",
              sm: "3rem",
              md: "3.3rem",
              xl: "3.8rem",
            }}
            textAlign={{ xs: "center", md: "center", lg: "left" }}
            sx={{ width: "100%", px: "10px", pb: "30px" }}
          >
            TenX Prime is ever present in helping you out with your investing
            needs.
          </Typography>
          <Typography
            lineHeight={1.4}
            variant="h3"
            fontSize="1.4rem"
            color="white"
            sx={{
              pb: "20px",
              fontSize: {
                xs: "1.2rem",
                sm: "1.7rem",
                md: "1.7rem",
                lg: "1.3rem",
                xl: "1.5rem",
              },
              px: "10px",
              textAlign: { xs: "center", md: "center", lg: "left" },
            }}
          >
            We are ready to hear you out and improve your trading experience.
            You are our top priority.
          </Typography>
        </div>
        <div className="Contact-Content-II"></div>
      </div>
      <div className="Contact-details">
        <div className="Contact-details-I">
          <Typography
            variant="h2"
            fontWeight="600"
            color="orange"
            fontSize="1.9rem"
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Connect With Us
          </Typography>
          <Divider />
          <Typography fontSize="1rem" sx={{ textAlign: "left", py: "20px" }}>
            Get in touch with us if you any questions. <br />
            We are here
          </Typography>
          <Typography
            variant="h2"
            fontWeight="600"
            color="orange"
            fontSize="1.9rem"
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Contact Us
          </Typography>
          <Divider />
          <Typography fontSize="1rem" sx={{ textAlign: "left", py: "20px" }}>
            You can reach us at the following email address:
          </Typography>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap="10px"
            sx={{ width: "250px" }}
            className="contact-email"
          >
            <div className="iconMail">
              <MailOutlineIcon fontSize="large" />
            </div>
            <a href={"mailto:support@tenxprime.com"}>
              <Typography fontSize="1.1rem">support@tenxprime.com</Typography>
            </a>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="left"
            gap="10px"
            sx={{ pt: "15px" }}
          >
            <div>
              <LocalPhoneIcon fontSize="large" />
            </div>
            <Typography fontSize="1.1rem">+61731033723</Typography>
          </Box>
          <Typography
            variant="h5"
            fontSize="1rem"
            sx={{ textAlign: "left", py: "20px" }}
          >
            Do not hesitate to contact us if you have any concerns or questions.
          </Typography>
          <br />
          <br />
          <Typography
            variant="h2"
            fontWeight="600"
            color="orange"
            fontSize="1.9rem"
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Our Location
          </Typography>
          <Divider />
          <Typography
            color="orange"
            fontSize="1.4rem"
            sx={{
              textAlign: { xs: "left", md: "left" },
              pt: "20px",
            }}
          >
            Registered Address
          </Typography>
          <Typography
            fontSize="1rem"
            sx={{ textAlign: "left", pb: "20px", pt: "10px" }}
          >
            TenX Prime Ltd. (26449 BC 2021),
            <br />
            Suite 305, Griffith Corporate Centre, Kingstown,
            <br />
            St. Vincent and The Grenadines.
          </Typography>
          <Typography
            fontWeight="600"
            color="orange"
            fontSize="1.4rem"
            sx={{
              textAlign: { xs: "left", md: "left" },
              pt: "20px",
            }}
          >
            Location Address
          </Typography>

          <Typography
            fontSize="1rem"
            sx={{ textAlign: "left", pb: "20px", pt: "10px" }}
          >
            Unit G04/3 Sam Sing Street ,
            <br />
            Waterloo NSW 2017.
            <br />
          </Typography>

          <Typography
            fontWeight="700"
            color="orange"
            fontSize="1.7rem"
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            TenX Prime Official Social Media Channels
          </Typography>
          <Divider />
          <Box
            display="flex"
            className="SocialContact"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={2}
            >
              <Grid item xs={5} md={2.5}>
                <a
                  href="https://www.facebook.com/profile.php?id=100084109445525&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Box className="socials Facebook"></Box>
                </a>
              </Grid>
              <Grid item xs={5} md={2.5}>
                <a
                  href="https://www.instagram.com/tenxprime_global/?igshid=YmMyMTA2M2Y%3D"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Box className="socials Instagram"></Box>
                </a>
              </Grid>
              <Grid item xs={5} md={2.5}>
                <a
                  href="https://twitter.com/tenxprimeglobal?s=21&t=yq47DE2GXh1xBVFUCjthAA"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Box className="socials Twitter"></Box>
                </a>
              </Grid>
              <Grid item xs={5} md={2.5}>
                <a
                  href="https://www.youtube.com/@tenxprime9121"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Box className="socials Youtube"></Box>
                </a>
              </Grid>
            </Grid>
          </Box>
        </div>
        {/* <div className="Contact-details-II">
          <Box
            sx={{
              height: "100%",

              width: "100%",
              mr: "20%",
            }}
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box className="GoogleMap-Box">
              <img src={GoogleMap} alt="googleMap" />
            </Box>
          </Box>
        </div> */}
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default contact;
