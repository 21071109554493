import { Grid, Typography } from "@mui/material";
import React from "react";
import Facebook from "../resource/FACEBOOK.png";
import Twitter from "../resource/TWITTER.png";
import Ig from "../resource/IG.png";
import Youtube from "../resource/YOUTUBE.png";

export const Socials = () => {
  return (
    <>
      <Grid container direction="column" sx={{ pb: "15px", width: "250px" }}>
        <Grid item display="flex" mb="5px">
          <Typography
            fontFamily="Mont-Bold"
            fontSize="1.5rem"
            sx={{
              color: "white",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Follow us
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ minWidth: "205px", maxWidth: "250px" }}
          >
            <Grid item xs={3}>
              <a
                href="https://www.facebook.com/profile.php?id=100084109445525&mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
            </Grid>
            <Grid item xs={3}>
              <a
                href="https://twitter.com/tenxprimeglobal?s=21&t=yq47DE2GXh1xBVFUCjthAA"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
            </Grid>
            <Grid item xs={3}>
              <a
                href="https://www.instagram.com/tenxprime_global/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={Ig} alt="Instagram" />
              </a>
            </Grid>
            <Grid item xs={3}>
              <a
                href="https://www.youtube.com/@tenxprime9121"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={Youtube} alt="Youtube" />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
