import "./nav.css";
import { useMediaQuery } from "@mui/material";
import Mobile from "./Mobile";
import NonMobile from "./NonMobile";
const Nav = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  return (
    <>
      <div className="navigation-component">
        {isMobile ? <Mobile /> : <NonMobile />}
      </div>
    </>
  );
};

export default Nav;
