import "./crypto.css";
import HeroBanner from "./components/HeroBanner";
import FourReasons from "./components/FourReasons";
import LiveQuotes from "./components/LiveQuotes";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";
const crypto = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <FourReasons />
      <LiveQuotes />

      <div>
        <Footer />
      </div>
    </>
  );
};

export default crypto;
