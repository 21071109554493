import "./ThreeSteps.css";
import { Container, Card, Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

import step1 from "../../resource/step1.png";
import step2 from "../../resource/step2.png";
import step3 from "../../resource/step3.png";
import Arrow from "../../resource/arrow.svg";
const ThreeSteps = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Fi-Steps">
        <Paper sx={{ backgroundColor: "transparent" }} elevation={12}>
          <Container maxWidth="xl" disableGutters={true}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="700"
              color="orange"
              fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3.3rem" }}
              sx={{ pt: "30px" }}
            >
              Start Trading In 3 Steps
            </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              gap={3}
              alignItems="center"
              justifyContent="center"
              py="20px"
              width="100%"
            >
              <Card
                elevation={6}
                sx={{ width: "250px", height: "330px", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContents: "center",
                    paddingY: "30px",
                  }}
                >
                  <Typography fontWeight="900" variant="h4" color="#0068ff">
                    STEP 1
                  </Typography>
                  <img src={step1} height="120px" alt="step 1" />
                  <Box className="Step-Card-Description">
                    <Typography
                      variant="h6"
                      fontSize="1.1rem"
                      textAlign="center"
                    >
                      Register your account <br />
                      and get verified
                    </Typography>
                  </Box>
                </Box>
              </Card>

              <div className="Arrow-FI">
                <img src={Arrow} alt="arrow" />
              </div>

              <Card
                elevation={6}
                sx={{ width: "250px", height: "330px", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContents: "center",
                    paddingY: "30px",
                  }}
                >
                  <Typography fontWeight="900" variant="h4" color="#0068ff">
                    STEP 2
                  </Typography>
                  <img src={step2} height="120px" alt="step 2" />
                  <Box className="Step-Card-Description">
                    <Typography
                      variant="h6"
                      fontSize="1.1rem"
                      textAlign="center"
                    >
                      Select types of account <br />
                      and open live trading account
                    </Typography>
                  </Box>
                </Box>
              </Card>

              <div className="Arrow-FI">
                <img src={Arrow} alt="arrow" />
              </div>

              <Card
                elevation={6}
                sx={{ width: "250px", height: "330px", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContents: "space-between",
                    height: "100%",
                    paddingY: "30px",
                  }}
                >
                  <Typography fontWeight="900" variant="h4" color="#0068ff">
                    STEP 3
                  </Typography>
                  <img src={step3} height="120px" alt="step3" />
                  <Box className="Step-Card-Description">
                    <Typography
                      variant="h6"
                      fontSize="1.1rem"
                      textAlign="center"
                    >
                      Make your deposit <br />& start trading
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
            <Container
              maxWidth="sm"
              sx={{ display: "flex", flexDirection: "column", py: "20px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    height: "60px",
                    borderRadius: "10px",
                    width: "250px",
                  }}
                  color="primary"
                >
                  <Typography
                    variant="h4"
                    fontWeight="700"
                    color="white"
                    fontSize="1.2rem"
                    sx={{ px: "30px" }}
                  >
                    Register Now
                  </Typography>
                </Button>
              </Box>
            </Container>
            <Container>
              <Typography
                variant="body1"
                fontSize="1.4rem"
                textAlign="center"
                color="white"
                sx={{ py: "40px" }}
              >
                Notice : Trading foreign exchange and CFD (contract for
                difference) on margin carries a high level of risk, and may not
                be suitable for all investors.The high degree of leverage can
                work against you as well as for you. Before deciding to invest
                in foreign exchange you should carefully consider your
                investment objectives, level of experience, and risk appetite.
              </Typography>
            </Container>
          </Container>
        </Paper>
      </div>
    </>
  );
};

export default ThreeSteps;
