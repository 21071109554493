import "./HeroBanner.css";
import { Typography, Container } from "@mui/material";
const HeroBanner = () => {
  return (
    <>
      <div className="T-MT4-wrapper">
        <Container maxWidth="xl">
          <div className="T-MT4-Banner">
            <div className="T-MT4-Banner-Details">
              <Typography
                variant="h1"
                color="orange"
                className="H-title"
                fontSize={{
                  xs: "2rem",
                  sm: "3rem",
                  md: "3.5rem",
                  xl: "4rem",
                }}
                lineHeight={{
                  xs: "2rem",
                  sm: "3rem",
                  md: "3.5rem",
                  xl: "4rem",
                }}
                textAlign={{ xs: "center", md: "left" }}
                sx={{ width: "100%" }}
              >
                The Universal <br /> Platform
              </Typography>
              <Typography
                sx={{ width: "100%", pt: "15px" }}
                variant="h6"
                color="white"
                fontSize={{
                  xs: "1.2rem",
                  sm: "1.8rem",
                  md: "2rem",
                  xl: "2rem",
                }}
                lineHeight={{
                  xs: "1.3rem",
                  sm: "1.8rem",
                  md: "2.1rem",
                  xl: "2rem",
                }}
                textAlign={{ xs: "center", md: "left" }}
                className="MT-D-B"
              >
                MT4 is one of the world’s most popular trading platforms,
                featuring automatic monitoring, advanced charts and live
                analysis. As one of the first ever providers of MT4, our years
                of experience makes a difference to your trading environment.
              </Typography>
            </div>
            <div className="T-MT4-Banner-details-2"></div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
