import { useState } from "react";
import "./PaymentMethods.css";
import { Button, Container, Box, Typography, Paper } from "@mui/material";

import Deposit from "./components/deposit";
import Withdrawl from "./components/withdrawal";

const PaymentMetods = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    if (isActive === true) {
      setIsActive(false);
    }
  };
  const handleClick2 = () => {
    if (isActive === false) {
      setIsActive(true);
    }
  };
  return (
    <>
      <div className="wtdp-container">
        <Box className="Tab">
          <Box
            className={isActive ? "Deposit active" : "Deposit"}
            onClick={handleClick2}
          >
            <Typography variant="h6" fontweight="600" textAlign="center">
              Deposit
            </Typography>
          </Box>
          <Box
            className={!isActive ? "With active" : "With"}
            onClick={handleClick}
          >
            <Typography variant="h6" fontweight="600" textAlign="center">
              Withdrawals
            </Typography>
          </Box>
        </Box>
        <Container disableGutters={true}>
          <Paper>{isActive ? <Deposit /> : <Withdrawl />}</Paper>
        </Container>
      </div>
    </>
  );
};

export default PaymentMetods;
