import "./HeroBanner.css";
import { Container, Grid, Stack, Typography, Button, Box } from "@mui/material";
const HeroBanner = () => {
  return (
    <>
      <div className="MarWrapper">
        <div className="vectorTrader">
          <Container maxWidth="xl">
            <Box
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
              gap={2}
              sx={{
                px: "20px",
              }}
              className="MarketStack"
            >
              <Box className="MM-B-T">
                <Typography
                  variant="h1"
                  color="orange"
                  className="H-title"
                  fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3.3rem" }}
                  lineHeight={{
                    xs: "2.3rem",
                    sm: "2.8rem",
                    md: "3.3rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                  sx={{ width: "100%" }}
                >
                  Forex Trading
                  <br /> For The Masses
                </Typography>
              </Box>
              <Box className="MM-B-D">
                <Typography
                  sx={{ width: "100%" }}
                  variant="h6"
                  color="white"
                  className="Slide-Description"
                  fontSize={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2rem",
                    xl: "2rem",
                  }}
                  lineHeight={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2.1rem",
                    xl: "2rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  The dynamic way of investing that generates
                  <br /> swift massive returns.
                </Typography>
              </Box>
              <Box className="MM-B-B">
                <a
                  href="https://client.tenxprime.com/register"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="market-main-button"
                    sx={{ borderRadius: "10px", width: "250px" }}
                  >
                    <Typography
                      variant="p"
                      color="white"
                      fontWeight="600"
                      fontSize="1.3rem"
                      className="nav-button"
                      sx={{ textTransform: "none" }}
                    >
                      Create Account
                    </Typography>
                  </Button>
                </a>
              </Box>
            </Box>
            <div className="MarEmpty"></div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
