import "./MT4TradingPlatform.css";
import { Typography, Container } from "@mui/material";
import AppStore from "../../resource/AppsStore.png";
import GooglePlay from "../../resource/GooglePlay.png";
import Macbook from "../../resource/Macbook.png";
const MT4TradingPlatform = () => {
  return (
    <>
      <Container
        sx={{
          py: "60px",
          minHeight: "55vh",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div className="H-MT4">
          <div className="Mac-wrapper">
            <div className="H-mac-Iphone"></div>
            {/* <img src={Macbook} alt="Mac-book" height="500vh" /> */}
          </div>

          <div className="H-MT4-details">
            <Typography
              fontWeight="600"
              fontSize="3rem"
              color="orange"
              lineHeight="3rem"
              sx={{ px: "15px", textAlign: { xs: "center", md: "left" } }}
            >
              MT4 Trading <br />
              Platform
            </Typography>
            <Typography
              variant="body1"
              fontSize="1.2rem"
              sx={{
                py: "10px",
                px: "15px",
                textAlign: "justify",
              }}
            >
              Smartphones and tablets are indispensable in trading when you are
              away from your computer. Use the mobile versions of MetaTrader 4
              on your Android and Apple iOS devices to trade in the financial
              markets.
            </Typography>
            <div className="AppDownload">
              <a
                href="https://download.mql5.com/cdn/mobile/mt4/ios?server=TenXPrime-DEMO,TenXPrime-REAL%20SERVER"
                target="_blank"
                className="h-link"
                rel="noreferrer noopener"
              >
                <div className="app1">
                  <img src={AppStore} alt="App Stroe" height="100px" />
                </div>
              </a>

              <a
                className="h-link"
                href="https://download.mql5.com/cdn/mobile/mt4/android?server=TenXPrime-DEMO,TenXPrime-REAL%20SERVER"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="app2">
                  <img src={GooglePlay} alt="App Stroe" height="100px" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MT4TradingPlatform;
