import "./home.css";
import Carousel from "./components/Carousel";
import Map from "./components/Map";
import CardHome from "./components/CardHome";
import MT4TradingPlatform from "./components/MT4TradingPlatform";
import NegativeBalance from "./components/NegativeBalance";
import WhyTenXPrime from "./components/WhyTenXPrime/Index";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";

const home = () => {
  return (
    <>
      <Nav />
      <div className="Home">
        <div className="Empty-spacing"></div>
        <Carousel />
        <Map />
        <CardHome />
        <MT4TradingPlatform />
        <NegativeBalance />
        <WhyTenXPrime />
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default home;
