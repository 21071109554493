import "./WeOffer.css";
import { Typography, Container, Paper, Button, Box } from "@mui/material";
import meta from "../../resource/logometatrade4.svg";
const WeOffer = () => {
  return (
    <>
      <div className="marPriceCard">
        <Container maxWidth="lg">
          <Container maxWidth="md">
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="600"
              color="orange"
              fontSize={{ xs: "2.2rem", sm: "2.5rem", md: "3.3rem" }}
            >
              We Offer Different Account Types Based On Your Needs
            </Typography>
          </Container>
          <Container maxWidth="sm">
            {/* <div className="M-card-wrapper">
              <div className="M-card-content">
                <div className="M-card2-content">
                  <div className="M-card1-content"> */}
            <Paper
              elevation={5}
              sx={{ borderRadius: "20px", mt: "30px", mb: "30px" }}
            >
              <div className="M-card1-details">
                <Typography
                  variant="h5"
                  fontStyle="italic"
                  fontWeight="500"
                  color="orange"
                  flexWrap="wrap"
                  textAlign="center"
                  fontSize={{
                    xs: "2.1rem",
                    sm: "2.6rem",
                    md: "3.1rem",
                  }}
                >
                  TenX MT4
                </Typography>
                <img src={meta} alt="metaTrader4" width="90%" />

                <div className="M-card-micro-details">
                  <div className="detailGrey">
                    <div className="spread-details detail">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className="Box-Card"
                      >
                        <Typography
                          fontWeight="600"
                          variant="h5"
                          fontSize={{
                            xs: "1.2rem",
                            sm: "2rem",
                            md: "2.3rem",
                          }}
                        >
                          Spread From
                        </Typography>
                        <Typography
                          fontWeight="600"
                          variant="h5"
                          fontSize={{
                            xs: "1.2rem",
                            sm: "2rem",
                            md: "2.3rem",
                          }}
                        >
                          0.1 pips
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        fontWeight="400"
                        fontSize="1.1rem"
                      >
                        Floating spread, markup
                      </Typography>
                    </div>
                  </div>
                  <div className="detailwhie detail">
                    <div className="spread-details">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className="Box-Card"
                      >
                        <Typography
                          fontWeight="600"
                          variant="h5"
                          fontSize={{
                            xs: "1.2rem",
                            sm: "2rem",
                            md: "2.3rem",
                          }}
                        >
                          Min deposit
                        </Typography>
                        <Typography
                          fontWeight="600"
                          variant="h5"
                          fontSize={{
                            xs: "1.2rem",
                            sm: "2rem",
                            md: "2.3rem",
                          }}
                        >
                          $25
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        fontWeight="400"
                        fontSize="1.1rem"
                      >
                        Favourable $100
                      </Typography>
                    </div>
                  </div>
                  <div className="detailGrey detail">
                    <Box pl="10px">
                      <Typography
                        fontWeight="600"
                        variant="h5"
                        fontSize={{
                          xs: "1.2rem",
                          sm: "2rem",
                          md: "2.3rem",
                        }}
                      >
                        1 : 2000 leverage
                      </Typography>
                    </Box>
                  </div>
                </div>
                <a
                  href="https://client.tenxprime.com/register"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px" }}
                  >
                    <Typography
                      color="white"
                      fontSize="1.2rem"
                      sx={{ textTransform: "none" }}
                    >
                      Register Now
                    </Typography>
                  </Button>
                </a>
                <div className="M-card-divider"></div>
                <Typography
                  fontWeight="600"
                  textAlign="center"
                  variant="p"
                  fontSize="1.2rem"
                  color="#6fe866"
                  sx={{ pb: "15px", px: "15px" }}
                >
                  More about TenX <br /> MetaTrader 4 Account
                </Typography>
              </div>
            </Paper>
            {/* </div>
                </div>
              </div>
            </div> */}
          </Container>
        </Container>
      </div>
    </>
  );
};

export default WeOffer;
