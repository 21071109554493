import "./FourReasons.css";
import { Container, Typography, Box } from "@mui/material";
import SimpleAccordion from "./component/SimpleAccordion";
const FourReasons = () => {
  return (
    <>
      <div className="cryReasons">
        <Container maxWidth="xl">
          <Container maxWidth="lg">
            <Box>
              <Typography
                textAlign="center"
                fontWeight="600"
                color="orange"
                variant="h3"
                className="A-Title"
                sx={{
                  fontSize: {
                    xs: "2rem",
                    sm: "2.8rem",
                    md: "3.2rem",
                    lg: "3.8rem",
                    xl: "4rem",
                  },
                }}
              >
                4 Reasons To Trade Crypto - currencies With TenX Prime
              </Typography>
            </Box>
          </Container>
          <div className="Cry-Accordian">
            <Container maxWidth="lg">
              <SimpleAccordion />
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FourReasons;
