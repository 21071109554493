import React from "react";
import { Typography, Button, Container } from "@mui/material";
import "./Map.css";

const Map = () => {
  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        fontWeight="600"
        color="orange"
        fontSize={{
          xs: "2.3rem",
          sm: "2.8rem",
          md: "3.3rem",
          lg: "3.8rem",
          xl: "4.rem",
        }}
        sx={{ py: "20px", backgroundColor: "#ebebeb99" }}
      >
        Start Trading In 3 Steps
      </Typography>
      <div className="H-Map-container">
        <Container maxWidth="lg">
          <div className="H-Map-Line">
            <Container maxWidth="md" className="HML-container">
              <div className="HML-container">
                <div className="H-Map-row1">
                  <div className="HMR-content-1">
                    <Typography
                      fontWeight="600"
                      color="#0068ff"
                      textAlign="right"
                      fontSize="3.2rem"
                      sx={{ lineHeight: "60px" }}
                    >
                      #01
                    </Typography>
                    <Typography
                      textAlign="right"
                      sx={{
                        fontSize: { sm: "1rem", md: "1.4rem" },
                      }}
                    >
                      Register and verify your account
                    </Typography>
                  </div>
                  <div className="HMR-content-2">
                    <Typography
                      color="#0068ff"
                      fontWeight="600"
                      textAlign="right"
                      fontSize="3.2rem"
                    >
                      #03
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { sm: "1rem", md: "1.4rem" },
                      }}
                      textAlign="right"
                    >
                      Make a deposit and start trading
                    </Typography>
                  </div>
                </div>
                <div className="H-Map-row2">
                  <div className="H-Map-button">
                    <a
                      href="https://client.tenxprime.com/register"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer noopener"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ p: "20px", width: "200px", borderRadius: "10px" }}
                      >
                        <Typography fontWeight="700" color="white">
                          Register Now
                        </Typography>
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="H-Map-row3">
                  <div className="HMR-content-3">
                    <Typography
                      color="#0068ff"
                      fontWeight="600"
                      textAlign="left"
                      fontSize="3.2rem"
                    >
                      #02
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { sm: "1rem", md: "1.4rem" },
                      }}
                      textAlign="left"
                    >
                      Select account type and create a <span>REAL</span> trading
                      account
                    </Typography>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Map;
