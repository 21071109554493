import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Card, Divider } from "@mui/material";
import { useState } from "react";

const SimpleAccordionMarket = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  return (
    <div>
      <Box
        display="flex"
        alignItems="flex-start"
        flexWrap="wrap"
        justifyContent="space-around"
        gap={2}
        pb="20px"
      >
        <Card
          onMouseOver={() => setExpanded1(true)}
          onMouseLeave={() => setExpanded1(false)}
          onTouchStart={() => setExpanded1(true)}
          onTouchEnd={() => setExpanded1(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: { sm: "100%", md: "250px" } }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded1}
          >
            <AccordionSummary
              id="pannel1-header"
              aria-controls="panel1a-content"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="600">
                  01
                </Typography>
                <Typography fontWeight="700" fontSize="2rem" variant="body1">
                  Lowest
                  <br />
                  Spreads
                </Typography>
                <Box sx={{ width: "100px" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Typography variant="body1" fontSize="1.2rem">
                Allows you to trade currency pairs with orders closest to the
                market price.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded2(true)}
          onMouseLeave={() => setExpanded2(false)}
          onTouchStart={() => setExpanded2(true)}
          onTouchEnd={() => setExpanded2(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: { sm: "100%", md: "250px" } }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded2}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel2-header"
            >
              <Box
                sx={{
                  height: "300px",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="600">
                  02
                </Typography>
                <Typography fontWeight="700" fontSize="2rem" variant="body1">
                  Simplest Market To Enter
                </Typography>
                <Box sx={{ width: "100px" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" fontSize="1.2rem">
                Grow a small account exponentially by placing small-size orders.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded3(true)}
          onMouseLeave={() => setExpanded3(false)}
          onTouchStart={() => setExpanded3(true)}
          onTouchEnd={() => setExpanded3(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: { sm: "100%", md: "250px" } }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded3}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel3-header"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="600">
                  03
                </Typography>
                <Typography fontWeight="700" fontSize="2rem" variant="body1">
                  Great Leverage
                </Typography>
                <Box sx={{ width: "100px" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" fontSize="1.2rem">
                With 1:500 leverage ratio, you can boost your currency trading
                to the max.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded4(true)}
          onMouseLeave={() => setExpanded4(false)}
          onTouchStart={() => setExpanded4(true)}
          onTouchEnd={() => setExpanded4(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: { sm: "100%", md: "250px" } }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded4}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel4-header"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="600">
                  04
                </Typography>
                <Typography fontWeight="700" fontSize="2rem" variant="body1">
                  World’s Largest Trade Volume
                </Typography>
                <Box sx={{ width: "100px" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" fontSize="1.2rem">
                Thousands of currency operations are performed every second
                worldwide.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Box>
    </div>
  );
};

export default SimpleAccordionMarket;
