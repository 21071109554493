import "./HeroBanner.css";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import bonus from "../../resource/Bonus.png";
import NoComHidChar from "../../resource/NoComission.png";
const HeroBanner = () => {
  return (
    <>
      <div className="TSWD-content">
        <Container>
          <div className="inner-TSWD-content">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
              gap={3}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  color="orange"
                  className="H-title"
                  fontSize={{
                    xs: "2.4rem",
                    sm: "3rem",
                    md: "3.5rem",
                    xl: "4rem",
                  }}
                  lineHeight={{
                    xs: "2.2rem",
                    sm: "3.2rem",
                    md: "3.5rem",
                    xl: "4rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  Deposits &<br />
                  Withdrawals
                </Typography>
              </Grid>
              <Grid item xs={12} md={3.4}>
                <Box
                  p="10px"
                  className="TSWD-boxes"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontWeight="900"
                    color="orange"
                    variant="h3"
                    className="A-Title"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "2.3rem",
                        md: "2rem",
                        lg: "2rem",
                        xl: "2.5rem",
                      },
                    }}
                  >
                    Effortless Transfers
                  </Typography>
                  <Typography
                    color="black"
                    variant="body1"
                    fontSize="1.2rem"
                    textAlign="center"
                  >
                    No Commissions Or
                    <br />
                    Hidden Charges
                  </Typography>
                  <img
                    src={NoComHidChar}
                    alt="No commission or hidden charges"
                    className="TSWD-img-1"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3.4}>
                <Box
                  p="10px"
                  className="TSWD-boxes"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontWeight="900"
                    color="orange"
                    variant="h3"
                    className="A-Title"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "2.3rem",
                        md: "2rem",
                        lg: "2rem",
                        xl: "2.5rem",
                      },
                    }}
                  >
                    Instantaneous Transactions
                  </Typography>
                  <Typography
                    color="black"
                    variant="body1"
                    fontSize="1.2rem"
                    textAlign="center"
                  >
                    Experience smooth transaction when you make a deposit or
                    withdrawal
                  </Typography>
                  <a
                    href="https://client.tenxprime.com/register"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer noopener"
                  >
                    <Button variant="contained" sx={{ borderRadius: "10px" }}>
                      <Typography variant="p" color="white" fontSize="1.2rem">
                        Get Started
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} md={3.4}>
                <Box
                  p="10px"
                  className="TSWD-boxes"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontWeight="900"
                    color="orange"
                    variant="h3"
                    className="A-Title"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "2.3rem",
                        md: "2rem",
                        lg: "2rem",
                        xl: "2.5rem",
                      },
                    }}
                  >
                    Elevate on every deposit
                  </Typography>
                  <Typography
                    color="black"
                    variant="body1"
                    fontSize="1.2rem"
                    textAlign="center"
                  >
                    50% bonus boosts
                    <br />
                    your deposit by half
                  </Typography>
                  <img
                    src={bonus}
                    alt="No commission or hidden charges"
                    className="TSWD-img-2"
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
