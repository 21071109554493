import "./ForeignCurrencies.css";
import { Container, Typography } from "@mui/material";
import SimpleAccordionMarket from "./components/SimpleAccordianMarket";

const ForeignCurrencies = () => {
  return (
    <>
      <div className="marForex">
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            textAlign="center"
            fontWeight="600"
            color="orange"
            fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3.3rem" }}
          >
            Foreign Currencies Are The Essence of Forex
          </Typography>
          <Typography fontSize="1.3rem" textAlign="center" sx={{ py: "30px" }}>
            Take full advantage of what it has to offer.
          </Typography>
          <SimpleAccordionMarket />
        </Container>
      </div>
    </>
  );
};

export default ForeignCurrencies;
