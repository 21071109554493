import "./market.css";
import HeroBanner from "./components/HeroBanner";
import ForeignCurrencies from "./components/ForeignCurrencies";
import ThreeReasons from "./components/ThreeReasons";
import WeOffer from "./components/WeOffer";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";

const market = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <ForeignCurrencies />
      <ThreeReasons />
      <WeOffer />
      <div>
        <Footer />
      </div>
    </>
  );
};

export default market;
