import "./Herobanner.css";
import { Container, Typography, Box } from "@mui/material";
const HeroBanner = () => {
  return (
    <>
      <div className="Fi-container">
        <Container maxWidth="xl">
          <div className="Fi-Banner">
            <Box className="Fi-title">
              <Typography
                variant="h1"
                color="orange"
                className="H-title"
                fontSize={{
                  xs: "2.9rem",
                  sm: "3.8rem",
                  md: "4rem",
                  xl: "4.2rem",
                }}
                lineHeight={{
                  xs: "3.6rem",
                  sm: "3.8rem",
                  md: "4rem",
                  xl: "4.2rem",
                }}
                textAlign={{ xs: "center", md: "left" }}
                sx={{ width: "100%" }}
              >
                Financial <br />
                Instrument
              </Typography>
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
