import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./Mobile.css";
import LoginIcon from "@mui/icons-material/Login";
import { Box, Drawer, Typography, IconButton, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import NavItems from "../components/NavItems";

const Mobile = () => {
  const [isDrawer, setIsDrawer] = useState(false);
  const [isDrawerII, setIsDrawerII] = useState(false);
  const [windowWidth, setWindowWidth] = useState({
    windowWid: window.innerWidth,
  });

  const getWindowWidth = () => {
    setWindowWidth({ windowWid: window.innerWidth });
  };
  useEffect(() => {
    window.addEventListener("resize", getWindowWidth);
    return () => {
      window.removeEventListener("resize", getWindowWidth);
    };
  }, [windowWidth]);

  const handleDrawerClose = () => {
    setIsDrawer(false);
  };
  const handleDrawerOpen = () => {
    setIsDrawer(true);
  };
  const handleDrawerCloseII = () => {
    setIsDrawerII(false);
  };

  return (
    <>
      <nav className="navigation-component-mobile">
        <Paper
          elevation={6}
          className="mobile-Paper"
          sx={{ backgroundColor: "transparent" }}
        >
          <Box pl="20px">
            <IconButton size="large" onClick={handleDrawerOpen}>
              <MenuIcon fontSize="large" sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <Link to="/" className="HomeRouter">
            <div className="Logo-mobile"></div>
          </Link>
          <Box pr="20px">
            <a
              href="https://client.tenxprime.com/login"
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconButton size="large">
                <LoginIcon fontSize="large" sx={{ color: "white" }} />
              </IconButton>
            </a>
          </Box>
          <Drawer
            anchor="left"
            open={isDrawer}
            onClose={handleDrawerClose}
            sx={{ padding: "0" }}
            className="Drawer"
          >
            <Box
              p={2}
              width={`${windowWidth.windowWid * 0.75}px`}
              className="Menu-drawer"
              sx={{ height: "100%" }}
            >
              <NavItems />
            </Box>
          </Drawer>
          <Drawer
            anchor="right"
            open={isDrawerII}
            onClose={handleDrawerCloseII}
            hideBackdrop={false}
            elevation={16}
          >
            <Box
              p={1}
              height="100vh"
              width={`${windowWidth.windowWid * 0.8}px`}
              className="SI-Box"
            >
              <Box className="SI-box-controlls">
                <Typography
                  fontFamily="Poppins"
                  fontWeight="700"
                  fontSize="2rem"
                  color="white"
                  sx={{ pl: "20px" }}
                >
                  Log In
                </Typography>
                <IconButton size="large" onClick={handleDrawerCloseII}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#0068ff" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  textAlign: "center",
                  height: "30px",
                }}
              >
                {"Copyright © "}
                TenX Prime Limited
              </Typography>
            </Box>
          </Drawer>
        </Paper>
      </nav>
    </>
  );
};

export default Mobile;
