import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import PaymentIcon from "@mui/icons-material/Payment";
import AddCardIcon from "@mui/icons-material/AddCard";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Button, Typography, Box, useMediaQuery } from "@mui/material";
import instant from "../../../../resource/Instant.svg";
import bitCoin from "../../../../resource/Bitcoin.svg";
import Visa from "../../../../resource/Visa.svg";
import Wire from "../../../../resource/Wire.svg";

export default function CustomPaginationActionsTable() {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <Paper
              sx={{
                backgroundColor: "white",
                minWidth: "200px",
                minHeight: "90px",
                position: "sticky",
                left: "0",
                borderRadius: "0",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "white",
                  minWidth: "200px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="600"
                  textAlign="left"
                  color="grey"
                  fontSize="1.1rem"
                >
                  Payment
                </Typography>
              </TableCell>
            </Paper>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography
                variant="body1"
                fontWeight="600"
                textAlign="left"
                color="grey"
                fontSize="1.1rem"
              >
                Minimum Amount
              </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography
                variant="body1"
                fontWeight="600"
                textAlign="left"
                color="grey"
                fontSize="1.1rem"
              >
                Commission
              </Typography>
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "white",
                minWidth: "200px",
                position: "sticky",
                left: "0",
                borderRadius: "0",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "white",
                  Width: "100px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={instant} alt="Instant Bank Transfer" />
              </TableCell>
            </Paper>
            <TableCell sx={{ minWidth: "170px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.3rem">
                25.00 USD
              </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.3rem">
                Free
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: "white",
                minWidth: "250px",
                height: "90px",
                p: "5px",
              }}
            >
              {isMobile ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <PaymentIcon fontSize="large" sx={{ color: "black" }} />
                    Withdraw
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "10px",
                    py: "10px",
                    width: "200px",
                    ml: "16px",
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="600"
                    variant="body1"
                    fontSize="1.1rem"
                  >
                    Make A Deposit
                  </Typography>
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "white",
                minWidth: "200px",
                position: "sticky",
                left: "0",
                borderRadius: "0",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "white",
                  Width: "100px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Wire} alt="Wire Bank Transfer" />
              </TableCell>
            </Paper>
            <TableCell sx={{ minWidth: "170px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.3rem">
                25.00 USD
              </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.2rem">
                Free
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: "white",
                minWidth: "250px",
                height: "90px",
                p: "5px",
              }}
            >
              {isMobile ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <PaymentIcon fontSize="large" sx={{ color: "black" }} />
                    Withdraw
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "10px",
                    py: "10px",
                    width: "200px",
                    ml: "16px",
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="600"
                    variant="body1"
                    fontSize="1.1rem"
                  >
                    Make A Deposit
                  </Typography>
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "white",
                minWidth: "200px",
                position: "sticky",
                left: "0",
                borderRadius: "0",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "white",
                  Width: "100px",
                  height: "90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={bitCoin} alt="Bitcoin Transfer" />
              </TableCell>
            </Paper>
            <TableCell sx={{ minWidth: "170px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.3rem">
                0.00037 BTC
              </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.2rem">
                Free
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: "white",
                minWidth: "250px",
                height: "90px",
                p: "5px",
              }}
            >
              {isMobile ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <PaymentIcon fontSize="large" sx={{ color: "black" }} />
                    Withdraw
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "10px",
                    py: "10px",
                    width: "200px",
                    ml: "16px",
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="600"
                    variant="body1"
                    fontSize="1.1rem"
                  >
                    Make A Deposit
                  </Typography>
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "white",
                minWidth: "200px",
                position: "sticky",
                left: "0",
                borderRadius: "0",
              }}
            >
              <TableCell
                sx={{
                  backgroundColor: "white",
                  minWidth: "200px",
                  Width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Visa} alt="Visa Bank Transfer" />
              </TableCell>
            </Paper>
            <TableCell sx={{ minWidth: "170px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.3rem">
                25.00 USD
              </Typography>
            </TableCell>
            <TableCell sx={{ minWidth: "150px" }}>
              <Typography variant="body1" fontWeight="600" fontSize="1.2rem">
                Free
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                minWidth: "250px",
                height: "90px",
                p: "5px",
              }}
            >
              {isMobile ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <PaymentIcon fontSize="large" sx={{ color: "black" }} />
                    Withdraw
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "10px",
                    py: "10px",
                    width: "200px",
                    ml: "16px",
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="600"
                    variant="body1"
                    fontSize="1.1rem"
                  >
                    Make A Deposit
                  </Typography>
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
