import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Breadcrumb() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorE2, setAnchorE2] = useState(false);
  const [anchorE3, setAnchorE3] = useState(false);
  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorE3);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(false);
  };
  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE3(false);
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("TenX_50_Deposit_Bonus.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "TenX_50_Deposit_Bonus.pdf";
        alink.click();
      });
    });
  };
  return (
    <Stack spacing={2} sx={{ py: "5px" }}>
      <Box display="flex" alignItems="center" gap={2}>
        <Link to="/about" className="link">
          <Typography variant="h5" fontWeight="500" fontSize="1.25rem">
            About
          </Typography>
        </Link>
        <NavigateNextIcon />
        <Typography
          variant="h5"
          fontWeight="500"
          fontSize="1.25rem"
          aria-controls={open1 ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open1 ? "true" : undefined}
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          Market
        </Typography>

        <NavigateNextIcon />
        <Typography
          variant="h5"
          fontWeight="500"
          fontSize="1.25rem"
          aria-controls={open2 ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open2 ? "true" : undefined}
          onClick={handleClick2}
          sx={{ cursor: "pointer" }}
        >
          Trading Services
        </Typography>
        <NavigateNextIcon />

        <Typography
          variant="h5"
          fontWeight="500"
          fontSize="1.25rem"
          ria-controls={open3 ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open3 ? "true" : undefined}
          onMouseOver={handleClick3}
          sx={{ cursor: "pointer" }}
        >
          Promotions
        </Typography>

        {/**Box DropDowns */}
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open1}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ top: "34px" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Link to="/market" className="link">
            <MenuItem onClick={handleClose}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Market Main
              </Typography>
            </MenuItem>
          </Link>
          <Link to="/marketEco" className="link">
            <MenuItem onClick={handleClose}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Economic Calendar
              </Typography>
            </MenuItem>
          </Link>
        </Menu>
        <Menu
          id="menu2"
          anchorEl={anchorE2}
          open={open2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ top: "34px" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Link to="/FinIn" className="link">
            <MenuItem onClick={handleClose2}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Financial Instruments
              </Typography>
            </MenuItem>
          </Link>
          <Link to="/Crypto" className="link">
            <MenuItem onClick={handleClose2}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Cryptopcurrencies
              </Typography>
            </MenuItem>
          </Link>
          <Link to="/TsAccount" className="link">
            <MenuItem onClick={handleClose2}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Account Type
              </Typography>
            </MenuItem>
          </Link>
          <Link to="/TsDepoWit" className="link">
            <MenuItem onClick={handleClose2}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                Deposit & Withdrawal
              </Typography>
            </MenuItem>
          </Link>
          <Link to="/TsMt4" className="link">
            <MenuItem onClick={handleClose2}>
              <Typography variant="h5" fontWeight="500" fontSize="1.1rem">
                MetaTrader 4
              </Typography>
            </MenuItem>
          </Link>
        </Menu>
        <Menu
          id="menu3"
          anchorEl={anchorE3}
          open={open3}
          onClose={handleClose3}
          onMouseOver={open3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ top: "34px" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box>
            <MenuItem>
              <Typography
                variant="h5"
                fontWeight="500"
                fontSize="1.1rem"
                onClick={onButtonClick}
              >
                50% Deposit Bonus
              </Typography>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </Stack>
  );
}
