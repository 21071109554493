import "./WhyTenXPrime.css";
import { Typography, Container, Grid } from "@mui/material";
import YesCard from "./components/YesCard";
import NoCommisions from "../../resource/NoCommisions.svg";
import NoHiddenFees from "../../resource/NoHiddenFees.svg";
import NoDelay from "../../resource/NoDelay.svg";
import NoSwaps from "../../resource/NoSwaps.svg";
const WhyTenXPrime = () => {
  return (
    <>
      <Grid item>
        <Container sx={{ paddingTop: "35px" }}>
          <Typography
            fontWeight="600"
            color="orange"
            variant="h4"
            textAlign="center"
            lineHeight="3.5rem"
          >
            Why choose TenX Prime for
            <br /> Forex Trading?
          </Typography>

          <Typography
            color="#1f1f1f"
            variant="h6"
            fontWeight="400"
            textAlign="center"
            paddingTop={3}
          >
            TenX Prime enables traders of all skill levels with an opportunity
            to maximize their profit by ensuring reliable forex trading
            conditions.
          </Typography>
          <div className="H-cards">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
              gap={2}
              sx={{ pt: "20px", pb: "30px" }}
            >
              <Grid item xs={7} sm={5} md={2}>
                <YesCard
                  title="No Commisions"
                  title2="on transactions"
                  svg={NoCommisions}
                />
              </Grid>
              <Grid item xs={7} sm={5} md={2}>
                <YesCard title="No hidden" title2="fees" svg={NoHiddenFees} />
              </Grid>
              <Grid item xs={7} sm={5} md={2}>
                <YesCard title="No" title2="swaps" svg={NoSwaps} />
              </Grid>
              <Grid item xs={7} sm={5} md={2}>
                <YesCard title="No" title2="delays" svg={NoDelay} />
              </Grid>
            </Grid>
          </div>
        </Container>
      </Grid>
    </>
  );
};

export default WhyTenXPrime;
