import React from "react";
import { Card, Container, Box, Grid, Typography } from "@mui/material";
import chooseCheck from "../../../resource/ChooseCheck.svg";
import "./YesCard.css";
const YesCard = ({ title, title2, svg }) => {
  return (
    <>
      <Box className="box-Card">
        <div className="Card-check">
          <img src={chooseCheck} alt="yes" className="yes_check" />
        </div>
        <Card elevation={15} className="Cards" sx={{ borderRadius: "15px" }}>
          <div className="center25">
            <Box
              m={2}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              flexDirection="column"
              className="center"
            >
              <img src={svg} alt="blah" className="Card-Image" />

              <Typography
                variant="body1"
                fontWeight="400"
                fontSize="1.1rem"
                textAlign="center"
              >
                {title}
                <br />
                {title2}
              </Typography>
            </Box>
          </div>
        </Card>
      </Box>
    </>
  );
};

export default YesCard;
