import "./WhyChoose.css";
import { Typography, Container, Grid } from "@mui/material";
import lowest from "../../resource/lowest.svg";
import Reliable from "../../resource/Reliable.svg";
import bonus from "../../resource/bonus.svg";
import noCommission from "../../resource/noCommission.svg";
import noDealer from "../../resource/noDealer.svg";
import trading from "../../resource/trading.svg";
const WhyChoose = () => {
  return (
    <div className="about-us">
      <div className="WhyTenX">
        <Typography
          textAlign="center"
          color="orange"
          fontWeight="700"
          variant="h3"
          className="A-Title"
          sx={{
            fontSize: {
              xs: "2.3rem",
              sm: "2.8rem",
              md: "3.2rem",
              lg: "3.8rem",
              xl: "4rem",
            },
          }}
        >
          Why Choose TenX Prime?
        </Typography>

        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            gap={2}
            sx={{ paddingBottom: "40px" }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={Reliable} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                Reliable and
                <br /> innovative broker
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={lowest} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                The lowest spreads
                <br /> in the industry
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={bonus} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                50% bonus on
                <br /> every deposit
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={noCommission} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                No commissions <br /> on deposit & <br /> withdrawal
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={trading} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                Trading on industry <br /> leading platforms
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={noDealer} alt="reliable icon" height="125rem" />
              <Typography
                variant="h5"
                fontSize="1.2rem"
                color="white"
                fontWeight="500"
                textAlign="center"
              >
                No dealer intervention <br /> and fair order <br /> execution
                profit
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default WhyChoose;
