import React, { useState } from "react";

/* Need to figure out how to change font size based on screen size */
import "./Footer.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider, Grid, Stack, Container, Modal, Button } from "@mui/material";
import { Socials } from "./components/Socials";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <Box sx={{ pt: "1rem" }}>
      <Typography variant="body1" sx={{ color: "white", textAlign: "center" }}>
        {"2023 © "}
        TenX Prime Limited, registration number: 26449 BC 2021.
        <br /> Contact: support@tenxprime.com / +61731033723
      </Typography>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  overflowY: "auto",
  boxShadow: 24,
  scroll: true,
  p: 4,
};

const Footer = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [open6, setOpen6] = useState(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const handleOpen6 = () => setOpen6(true);
  const handleClose6 = () => setOpen6(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <a
        href="https://client.tenxprime.com/register"
        target="_blank"
        style={{ textDecoration: "none" }}
        rel="noreferrer noopener"
      >
        <Stack
          bgcolor="#00c943"
          justifyContent="center"
          alignItems="center"
          pt="25px"
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#00b03b",
            },
            px: "10px",
          }}
        >
          <Typography
            fontWeight="600"
            variant="h6"
            sx={{
              color: "white",
              fontSize: {
                xs: "1.3rem",
                sm: "2.1rem",
                md: "2.1rem",
                lg: "2.3rem",
                xl: "2.5rem",
              },
              lineHeight: {
                xs: "1.3rem",
                sm: "2.1rem",
                md: "2.1rem",
                lg: "2.3rem",
                xl: "2.5rem",
              },
            }}
          >
            And a lot more.
          </Typography>
          <Typography
            textAlign="center"
            fontWeight="900"
            color="white"
            variant="h3"
            className="A-Title"
            sx={{
              fontSize: {
                xs: "1.6rem",
                sm: "2.1rem",
                md: "2.1rem",
                lg: "2.3rem",
                xl: "2.5rem",
              },
              lineHeight: {
                xs: "1.9rem",
                sm: "2.1rem",
                md: "2.1rem",
                lg: "2.3rem",
                xl: "2.5rem",
              },
              position: "relative",
              bottom: "15px",
            }}
          >
            Start your trading journey with TenX Prime.
          </Typography>
        </Stack>
      </a>
      {/** container here */}
      <Box
        component="footer"
        sx={{
          py: 2,
          px: 2,

          backgroundColor: "#0068ff",
        }}
      >
        <Container>
          <Box className="Footer-container">
            <Box>
              <Socials />
            </Box>
            <Box display="flex" gap="50px">
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  fontSize="1.2rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Media
                </Typography>
                <Link
                  to="/underconstruction"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="1.1rem"
                    sx={{
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Company news
                  </Typography>
                </Link>
                <Link
                  to="/underconstruction"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="1.1rem"
                    sx={{
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Videos
                  </Typography>
                </Link>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  fontSize="1.2rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Company
                </Typography>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="1.1rem"
                    sx={{
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    About us
                  </Typography>
                </Link>
                <Link
                  to="/underconstruction"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="1.1rem"
                    sx={{
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Awards
                  </Typography>
                </Link>
                <Link to="/Contact" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    fontWeight="400"
                    fontSize="1.1rem"
                    sx={{
                      color: "white",
                      textAlign: "left",
                    }}
                  >
                    Contact us
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
          <Divider
            color="white"
            sx={{
              my: "15px",
            }}
            direction="row"
            justifyContent="space-evenly"
          />
          <Box>
            <Typography
              variant="body1"
              fontSize="1rem"
              color="white"
              textAlign="justify"
            >
              Website is operated by TenX Prime Ltd instead of TenX Prime Pty
              Ltd, and customers registered on this website will open under TenX
              Prime Ltd, registered at St Vincent & The Grenadines Financial
              Services Authority (SVGFSA), number 26449 BC 2021 and is
              Registered address: Suite 305, Griffith Corporate Centre,
              Kingstown St. Vincent and The Grenadines.
              <br />
              <br />
              TenX Prime Ltd is a member of group of company TenX Prime Pty Ltd.
              <br />
              <br />
              TenX Prime Pty Ltd is authorised and regulated by the Australian
              Securities and Investments Commission (ASIC) with AFS Authorised
              Representative No. 001300934. Office address: Unit G04/3 Sam Sing
              Street, Waterloo NSW 2017, Australia.
              <br />
              <br />
              TenX Prime (PTY) LTD is an authorized Financial Services Provider
              and is regulated by the Financial Sector Conduct Authority(FSCA)
              with registration number 2021/906851/07 under FSP No 52035. The
              registered address is at J331 Bhasini Road Umlazi
              KWA-Zulu Natal 4066.
              <br />
              <br />
              TenX Prime Pty Ltd is not a product issuer of over-the-counter
              (OTC) derivatives and as such, it will not make a market in such
              products, nor transact with clients as principal.
              <br />
              <br />
              Prohibited Regions: TenX Prime Pty Ltd does not provide services
              for the residents of certain countries, such as Australia, Canada,
              Ethiopia, Gibraltar, Israel, New Zealand, North Korea,
              Philippines, Poland, Singapore, United Kingdom and United States
              of America.
              <br />
              <br />
              Investing in Over the Counter (OTC) foreign exchange and
              derivative products carries a high level of risk and is not
              suitable for all investors. You do not own, or have any rights to,
              the underlying assets which the OTC derivative is referring to.
              Before you decide whether or not to invest in these products, we
              encourage you to consider your investment objectives, your risk
              tolerance, and trading experience. You could lose substantially
              more than your initial investment so do not invest money you
              cannot afford to lose. We recommend that you seek independent
              advice before opening an account with us. We only provide general
              advice which does not consider your financial objectives or
              personal circumstances. The content of this website should not be
              interpreted as personal advice; Please seek advice from an
              independent financial or tax advisor if you have any questions.
            </Typography>
          </Box>
          <Divider
            color="white"
            sx={{
              my: "15px",
            }}
            direction="row"
            justifyContent="space-evenly"
          />
          <Grid
            container
            spacing={0.5}
            direction="row"
            sx={{ justifyContent: { xs: "center" } }}
            alignItems="center"
            md={12}
            m="auto"
          >
            <Grid
              item
              xs={6}
              md={2}
              sx={{
                color: "white",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={handleOpen1}>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize=".8rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Risk disclosure
                </Typography>
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="letterHead"></div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FULL RISK WARNING
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    TenX Prime group of companies ("TenX Prime") as the company
                    providing Investment Services to the Client in relation to
                    Financial Instruments, issued this Full Risk Warning on
                    provision of Investment Services in relation to Financial
                    Instruments ("Full Risk Warning").
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    HIGH RISK INVESTMENT
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Trading foreign exchange on margin carries a high level of
                    risk, and may not be suitable for all investors. The high
                    degree of leverage can work against you as well as for you.
                    Before deciding to trade foreign exchange, you should
                    carefully consider your investment objectives, level of
                    experience, and risk appetite. The possibility exists that
                    you could sustain a loss of some or all of your initial
                    investment and therefore you should not invest money that
                    you cannot afford to lose. You should be aware of all the
                    risks associated with foreign exchange trading, and seek
                    advice from an independent financial advisor if you have any
                    doubts.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    TENX PRIME MARKET OPINIONS
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Any opinions, news, research, analyses, prices, or other
                    information contained on this website is provided as general
                    market commentary, and does not constitute investment
                    advice. TenX Prime will not accept liability for any loss or
                    damage, including without limitation to, any loss of profit,
                    which may arise directly or indirectly from use of or
                    reliance on such information.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    ONLINE TRADING RISKS
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    There are risks associated with utilizing an Internet-based
                    deal execution trading system including, but not limited to,
                    the failure of hardware, software, and Internet connection.
                    Since TenX Prime does not control signal power, its
                    reception or routing via Internet, configuration of your
                    equipment or reliability of its connection, we cannot be
                    liable for communication failures, distortions or delays
                    when trading via the Internet.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    ACCURACY OF INFORMATION
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The content on this website is subject to change at any time
                    without notice, and is provided for the sole purpose of
                    assisting traders to make independent investment decisions.
                    TenX Prime has taken reasonable measures to ensure the
                    accuracy of the information on the website, however, does
                    not guarantee its accuracy, and will not accept liability
                    for any loss or damage which may arise directly or
                    indirectly from the content or your inability to access the
                    website, for any delay in or failure of the transmission or
                    the receipt of any instruction or notifications sent through
                    this website.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    MARKET RISKS AND ONLINE TRADING
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The trading platform provides sophisticated order entry and
                    tracking of orders. TenX Prime will make best efforts to
                    fill your trade at the price requested. Trading on-line, no
                    matter how convenient or efficient does not necessarily
                    reduce risks associated with currency trading. All quotes
                    and trades are subject to the terms and conditions of the
                    Client Agreement. Information in this site shall not be
                    construed to be a solicitation to buy or offer to sell any
                    security, product or service, nor shall any such security,
                    product or service be solicited, offered or sold in any
                    jurisdiction where such activity would be contrary to the
                    securities laws or other local laws and regulations.
                    Products and services in this site may not be available for
                    residents of certain nations.
                  </Typography>
                </Box>
              </Modal>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              sx={{
                color: "white",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={handleOpen2}>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize=".8rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Privacy policy
                </Typography>
              </Button>
              <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="letterHead"></div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Privacy Policy
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    TenX Prime respects each individual's right to privacy. We
                    value our relationship with you, and we take pride in
                    maintaining loyalty and respect with each individual client
                    by providing you with security. The provisions of this
                    notice apply to former clients as well as our current
                    clients.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    Personal Information
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    When you apply for or maintain a live account with TenX
                    Prime we collect personal information about you for business
                    purposes, such as evaluating your financial needs,
                    processing your requests and transactions, informing you
                    about products and services that may be of interest to you,
                    and providing customer service. Such information may
                    include:
                    <br />
                    <br />
                    Application information: Information you provide to us on
                    applications and other forms, such as your name, address,
                    birth date, Social Security number, occupation, assets, and
                    income; transaction information: Information about your
                    transactions with us and with our affiliates as well as
                    information about our communications with you. Examples
                    include your account balances, trading activity, your
                    inquiries, and our responses;
                    <br />
                    <br />
                    Verification information: Information necessary to verify
                    your identity, such as a passport or driver's license.
                    Examples also include background information about you we
                    receive from public records or from other entities not
                    affiliated with TenX Prime. Federal Law requires us to
                    collect certain information and take actions necessary to
                    verify your identity.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    WEBSITE COOKIES
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Cookies are small files containing information that a Web
                    site uses to track its visitors. TenX Prime may set and
                    access TenX Prime cookies on your computer, enabling us to
                    learn which advertisements and promotions bring users to our
                    Web site. TenX Prime or any of its divisions may use cookies
                    in connection with TenX Prime products and services to track
                    your activities on our Web sites. <br />
                    <br />
                    Cookies may contain unique identifies and are stored or used
                    in, including but not limited to, your computer or mobile
                    device, emails we send to you, and our web pages. By using
                    our website or mobile application, you consent to us on the
                    usage of Cookies.
                    <br />
                    <br />
                    Cookies may transmit information about you and your use of
                    our service, including but not limited to:
                    <br />
                    <br />
                  </Typography>
                  <Box sx={{ pl: 4 }}>
                    <ul>
                      <li className="General">IP Address;</li>
                      <li className="General">Browser and device type;</li>
                      <li className="General">
                        Website or mobile application visited before you came to
                        our website or mobile application;
                      </li>
                      <li className="General">
                        What page user viewed on our website or mobile
                        application; and
                      </li>
                      <li className="General">
                        Time spent on our website or mobile application.
                      </li>
                    </ul>
                  </Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Cookies may be stored persistently or stored only during an
                    individual user session. Cookies enable the Company to
                    provide and develop a more personalized browsing and usage
                    experience on our website and mobile application, and
                    improve the experience of displaying targeted advertising to
                    you.
                    <br />
                    <br />
                    The Company may allow third parties to use Cookies on our
                    website and mobile application to collect the same type of
                    information. Third parties may be able to associate the
                    information they collect with other additional information
                    they collected about you from other sources. The data might
                    be used by third parties to target advertising on other
                    websites based on your online activity. We do not
                    necessarily have access to or control over the Cookies they
                    use.
                    <br />
                    <br />
                    You may deactivate cookies by adjusting your internet
                    browser settings to disable, block or deactivate cookies, by
                    deleting your browsing history and clearing the cache from
                    your internet browser, if you do not wish your Cookies to be
                    collected. You may also use third-party solution such as
                    https://optout.networkadvertising.org/. You may also be able
                    to limit our sharing of some of this information through
                    your mobile device settings.
                    <br />
                    <br />
                    Such information that we collect and share would be
                    anonymous and not personally identifiable.
                    <br />
                    <br />
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    SECURITY TECHNOLOGY
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    TenX Prime uses Secure Socket Layer (SSL) encryption
                    technology in order to protect certain information that you
                    submit. This type of technology protects you from having
                    your information intercepted by anyone other than TenX Prime
                    while it is being transmitted to us. We work hard to ensure
                    that our Web sites are secure and that they meet industry
                    standards. We also use other safeguards such as firewalls,
                    authentication systems (e.g., passwords and personal
                    identification numbers), and access control mechanisms to
                    control unauthorized access to systems and data.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    SHARING INFORMATION WITH OUR PARTNERS
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    We may share personal information described above with our
                    affiliates/partners for business purposes, such as, but not
                    limited to, servicing customer accounts and informing
                    customers about new products and services, or to aid in the
                    trading activity of the company, its affiliates, or
                    employees, and as permitted by applicable law. Our
                    affiliates may include companies controlled or owned by us
                    as well as companies that have an ownership interest in our
                    company. The information we share with affiliates may
                    include any of the information described above, such as your
                    name, address, trading and account. <br />
                    <br />
                    information. Our affiliates maintain the privacy of your
                    information to the same extent TenX Prime does in accordance
                    with this Policy.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    SHARING INFORMATION WITH 3rd PARTIES
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    TenX Prime does not disclose your personal information to
                    third parties, except as described in this Policy. Third
                    party disclosures may include sharing such information with
                    non-affiliated companies that perform support services for
                    your account or facilitate your transactions with TenX
                    Prime, including those that provide professional, legal, or
                    accounting advice to TenX Prime. Non-affiliated companies
                    that assist TenX Prime in providing services to you are
                    required to maintain the confidentiality of such information
                    to the extent they receive it and to use your personal
                    information only in the course of providing such services
                    and only for the purposes that TenX Prime dictates.
                    <br />
                    <br />
                    We may also disclose your personal information to third
                    parties to fulfill your instructions or pursuant to your
                    expressed consent.
                    <br />
                    <br />
                    We want you to know that TenX Prime will not sell your
                    personal information.
                  </Typography>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    REGULATORY DISCLOSURE
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Under limited circumstances, TenX Prime may disclose your
                    personal information to third parties as permitted by, or to
                    comply with, applicable laws and regulations. For example,
                    we may disclose personal information to cooperate with
                    regulatory authorities and law enforcement agencies to
                    comply with subpoenas or other official requests, and as
                    necessary to protect our rights or property. Except as
                    described in this privacy policy, we will not use your
                    personal information for any other purpose, unless we
                    describe how such information will be used at the time you
                    disclose it to us or we obtain your permission.
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    OPT-OUT
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    You are not required to supply any of the personal
                    information that we may request; however, failure to do so
                    may result in our being unable to open or maintain your
                    account or to provide services to you. While we make every
                    effort to ensure that all information, we hold about you is
                    accurate, complete, and up to date, you can help us
                    considerably in this regard by promptly notifying us if
                    there are any changes to your personal information
                  </Typography>
                </Box>
              </Modal>
            </Grid>
            <Grid
              item
              xs={5}
              md={2}
              sx={{
                color: "white",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={handleOpen3}>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize=".8rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Return policy
                </Typography>
              </Button>
              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="letterHead"></div>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The Company may go for an exception in some cases when it is
                    necessary to return payment made by the credit card, but
                    only if the Client justifies the reason of doing this. The
                    Client may submit a request to refund in case service not
                    provided or not as described, the service received is not
                    functioning appropriately. A refund is possible in case when
                    the account had been deposited but no trades were done and
                    is made only on the credit card that was used for
                    depositing.
                    <br />
                    <br />
                    In order to start the return process, the Client shall
                    submit a cancellation request by emailing to
                    support@tenxprime.com. A cancellation request shall contain
                    the following information at least but not limited to:
                  </Typography>
                  <Box sx={{ pl: 3, mt: 2 }}>
                    <ul>
                      <li>
                        <Typography>full name of the Client;</Typography>
                      </li>
                      <li>
                        <Typography>residential address;</Typography>
                      </li>
                      <li>
                        <Typography>
                          contact e-mail and phone number(-s);
                        </Typography>
                      </li>
                      <li>
                        <Typography>trading account number(-s);</Typography>
                      </li>
                      <li>
                        <Typography>
                          initial payment amount, date of payment, used payment
                          method (i.e., credit/debit cards);
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          payment identification number (if any);
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          the reasons of the cancellation subject to the
                          conditions stated below.
                        </Typography>
                      </li>
                    </ul>
                  </Box>

                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    All the information in a cancellation request submitted to
                    the Company shall be identical to that originally submitted
                    in the initial payment.
                    <br />
                    <br />
                    All received cancellation requests to be dealt with by the
                    Company on the following terms and conditions:
                  </Typography>
                  <Box sx={{ pl: 3, mt: 2 }}>
                    <ul>
                      <li>
                        <Typography>
                          all cancellation requests shall be for genuine and
                          acceptable reasons, and those reasons shall be
                          described in detail by the Client in the cancellation
                          request;
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          all cancellation requests shall be submitted within 7
                          days from the moment of the initial payment; the
                          Company shall notify the Client in cases where it is
                          not possible to process the cancellation request due
                          to card scheme or payment institution rules;
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          all cancellation requests shall be processed within 2
                          business days as per term set by the Client Agreement
                          for claims related to non-trading operations or
                          exceptionally more; the Company shall notify the
                          Client in cases when additional time is required;
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          all cancellation requests shall be reviewed only if
                          the requested amount does not exceed initial payment
                          amount and as well as free margin of trading account;
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          any charges deducted by the payment institution and
                          also any loss or expense, if suffered or incurred by
                          the Company due to adverse exchange rate fluctuation
                          to be covered from the return amount.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Modal>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              sx={{
                color: "white",
              }}
            >
              <Button onClick={handleOpen4}>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize="0.8rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  Customer Agreement
                </Typography>
              </Button>
              <Modal
                open={open4}
                onClose={handleClose4}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="Customer-Particulars">
                  <div className="letterHead"></div>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    fontWeight="bold"
                  >
                    TERMS AND CONDITIONS TRADING CFO SERVICES
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <span className="strong-content">TENX PRIME Limited</span>{" "}
                    registered under registration number 26449 BC 2021 and
                    authorized by Business Companies (Amendment and
                    Consolidation) ACT, Chapter 149 of the revise laws of St
                    Vincent & Grenadines 2009 for trading in financial and
                    commodity- based derivative instruments and other
                    securities; and other associated parties issued these Terms
                    and Conditions on provision of Payment Services and
                    Investment Services in relation to Financial Instruments
                    ("Terms and Conditions").
                  </Typography>
                  <Box sx={{ pl: 3, mt: 2 }}>
                    <ol>
                      <li>
                        <Typography>
                          <span className="strong-content">"Agent"</span> shall
                          mean a third person acting on behalf of a Client based
                          on either a contractual relationship between clients
                          or the third person (Power of Attorney) or a binding
                          decision of authority stating that the third person is
                          a legal representative of the Client.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"AML Policy"</span> "
                          shall mean a set of internal rules and measures
                          imposed upon the employees of TENX PRIME with the
                          purpose of abiding to and/or being compliant with
                          applicable laws on the prevention of money laundering
                          and terrorist financing. Such measures include Know
                          Your Customer policy, ongoing monitoring of Clients'
                          data and Transactions and other rules as described on
                          TENX PRIME's Website.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Authorized Person"
                          </span>{" "}
                          shall mean a person, who is formally and properly
                          empowered to legally represent a Client in respect of
                          duties associated with their office at the time of
                          proposing acts, entering orders or in other way
                          dealing with TENX PRIME.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "TENX PRIME's Account"
                          </span>{" "}
                          shall mean an account opened with another financial
                          institution in the name of TENX PRIME under the
                          conditions described in Article V. point 8 of these
                          Terms and Conditions. The number of such an account is
                          communicated to the Client during the process of
                          entering into a Contract.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "TENX PRIME's Website"
                          </span>{" "}
                          shall mean a website found and run by TENX PRIME,
                          which contains updated information about TENX PRIME
                          and its services. The website can be found at the
                          following address: https://tenxprime.com/
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Client"</span> means
                          any person, who has in their own name entered into a
                          Contract (as this is defined below) and so established
                          legal rights and obligations between himself and TENX
                          PRIME arising from and in compliance with the Contract
                          and other documents, which are declared to be integral
                          part of the Contract.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Client Account"
                          </span>{" "}
                          shall mean an account opened with TENX PRIME solely in
                          the name of the Client after entering into a Contract
                          for the purpose of registering the balance and all
                          Transactions concerning funds of the Client.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Complementary Services"
                          </span>{" "}
                          shall mean services that are related to and accompany
                          Investment Services. Complementary services are
                          provided by TENX PRIME without further notice or
                          special consideration from the Client whenever such
                          services are necessary to enable or facilitate the
                          provision of Investment Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Conflict of interest"
                          </span>{" "}
                          shall mean a situation in which there is a potential
                          of weakening impartiality of one of the parties to the
                          Contract because of discrepancy between the party's
                          interest concerning the Contract and other
                          professional or self-interest. It also means cases of
                          discrepancy between parties' interests and public
                          interest.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Contract"</span>{" "}
                          shall mean the applicable contractual framework in
                          relation to financial services provided by TENX PRIME.
                          It is entered into by TENX PRIME and the Client and
                          includes the legal documentation considered to be an
                          integral part of the Contract, namely account
                          application filled by the Client, these Terms and
                          Conditions, Full Risk Warning, Anti Money Laundering
                          Policy, Trading Execution, FATCA & CRS and Privacy
                          Policy as updated from time to time and available at
                          TENX PRIME website.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Credit"</span> shall
                          mean a fictional amount assigned to the platform of
                          MetaTrader. This amount increases the value of equity.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Currency"</span> "
                          shall mean a medium of exchange in the form of money,
                          which is issued by a government and circulated within
                          an economy. Currency is the basis for trades on
                          foreign exchange market.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Exchange Rate"
                          </span>{" "}
                          shall mean the rate between two currencies in which
                          one currency will be exchanged for another.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Financial Instrument"
                          </span>{" "}
                          shall mean an investment instrument in form of real or
                          virtual document representing a legal agreement
                          involving some sort of monetary value.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Incorrect Quotation"
                          </span>{" "}
                          shall mean prices of Financial Instruments provided to
                          the Client that are significantly different from the
                          prices of their underlying instruments as provided by
                          the TENX PRIME's liquidity provider.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Introducing Partner"
                          </span>{" "}
                          shall mean a person acting as a tied agent in the
                          respect of referring clients to TENX PRIME based on a
                          contractual relationship between TENX PRIME and such
                          person. Unless stated otherwise in the agreement
                          between TENX PRIME and such person, the Introducing
                          Partner has no right to act in the name of TENX PRIME.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Investment Services"
                          </span>{" "}
                          shall mean trading in commodity based derivative
                          instruments and other securities.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Investment Questionnaire"
                          </span>{" "}
                          shall mean a series of questions concerning potential
                          Client's financial background, trading experience,
                          knowledge plans and investment goals.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Joint Account"
                          </span>{" "}
                          shall mean a Client Account owned by two or more
                          persons, all of them acting as one Client.
                        </Typography>
                      </li>

                      <li>
                        <Typography>
                          <span className="strong-content">"Login"</span> shall
                          mean a unique username, which allows Clients to access
                          Trading System, and further allows to access into the
                          Trading System after entering the username and
                          Password.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Market Data"</span>{" "}
                          shall mean in particular stream of Quotations and
                          market information by TENX PRIME to client.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Leverage"</span>{" "}
                          shall mean the use of borrowed capital to be able to
                          make larger trades with a limited amount of money.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Market Execution"
                          </span>{" "}
                          shall mean the method of execution when the Clients
                          are opening or closing their position(s), these orders
                          go to the open market where they are filled at the
                          best available price. There is a delay before the
                          trade is placed and when it is filled. This method of
                          execution does not allow stop-loss and profit from
                          orders will be specified and set at the moment of
                          placing a new trade.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "My TENX PRIME"
                          </span>{" "}
                          shall be a mean of distant communication for the
                          purpose of facilitating the process of entering into
                          the Contract, management of Client Account and
                          gathering necessary information about TENX PRIME’s
                          Clients. My TENX PRIME forms a significant part of
                          Trading System.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Opening Position"
                          </span>{" "}
                          shall mean the process of establishing or entering
                          into a trade. Once this process is started, legal
                          rights and obligations related to concerned trade
                          arise. Once this process is successfully completed, an
                          opened position is established.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Password"</span>{" "}
                          shall mean a sequence of characters, which is used to
                          determine a person requesting access to Trading
                          System.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Position Closing"
                          </span>{" "}
                          shall mean the process of termination of an opened
                          position. Once this process is successfully completed,
                          legal rights and obligations related to the concerned
                          position terminate together with the position, which
                          position is then considered as closed.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Power of Attorney"
                          </span>{" "}
                          shall mean a written authorization of third person to
                          represent Client and act on Client's behalf. Any
                          document suiting the definition in previous sentence
                          falls within this term, no matter how such a document
                          is actually named.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Platform"</span>{" "}
                          shall mean a specific software provided by TENX PRIME,
                          through which investors and traders can order and
                          execute the process of Opening Position, Closing
                          Position or in other ways managing their positions and
                          entering Transaction Orders. Platforms form a
                          significant part of Trading System.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Politically Exposed Person"
                          </span>{" "}
                          shall mean an individual who has been entrusted with
                          prominent public function, such person's close
                          relative or any person known to be close associate of
                          that Politically Exposed Person. Politically Exposed
                          Person is defined in Anti-Money Laundering Act,2006.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Quotation"</span>{" "}
                          shall mean specified prices of Financial Instruments
                          offered at specific time to the Client.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Spread"</span> shall
                          mean a variable difference between the price, at which
                          a client may buy and sell a particular Financial
                          Instrument. This variable spread varies within a
                          certain range. This range is movable according to the
                          internal bank market.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Technology Provider"
                          </span>{" "}
                          shall mean a third person providing TENX PRIME with
                          necessary technical means based on a contractual
                          relationship between such person and TENX PRIME.
                          Technology Provider is not an employee of TENX PRIME.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Trading Hours"
                          </span>{" "}
                          shall mean time frames during which the transactions
                          on particular Financial Instrument can be executed.
                          Trading Hours of TENX PRIME are generally from Sunday
                          5:15 PM (EST) through Friday 4:00 PM (EST).
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Trading System"
                          </span>{" "}
                          shall mean a complex of software and internet based
                          applications provided by TENX PRIME to the Client in a
                          way of connecting to the dedicated servers designated
                          for that purpose by TENX PRIME, facilitates the
                          process of provision of Investment and Supplementary
                          Services.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Transaction"</span>{" "}
                          shall mean an agreement between two parties (the buyer
                          and the seller). It states that the seller will pay
                          the buyer the difference between the current value of
                          an asset and its value at "contract time". If the
                          difference is negative, the buyer pays the seller
                          instead.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Transaction Account"
                          </span>{" "}
                          shall mean one or more accounts, which meet the
                          requirements set in Article IV. point 8. letter b) of
                          this document and which have successfully passed the
                          process of Verification.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Transaction Confirmation"
                          </span>{" "}
                          shall mean an automatic written confirmation of
                          successful execution of Transaction. The confirmation
                          is generated by Trading System and shows up in the
                          list of Transactions within Client Account.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Transaction Credit Card"
                          </span>{" "}
                          shall mean one or more credit or debit cards, which
                          meet the requirements set in Article IV. point 8.
                          letter a) of this Terms and Conditions and which have
                          successfully passed the process of Verification.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Transaction Margin"
                          </span>{" "}
                          shall mean collateral in the form of monetary funds,
                          which is necessary to be provided by Client for
                          particular opened position.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Transaction Order"
                          </span>{" "}
                          shall mean an order placed by Client for TENX PRIME to
                          conclude a Transaction.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Unavoidable Circumstances"
                          </span>{" "}
                          shall mean circumstances that arise independently of
                          the will of party obliged under the Contract and which
                          prevent this party of Contract from performing its
                          obligation, provided that it cannot be reasonably
                          expected that the obliged party could overcome such
                          circumstances or its consequences, and further that
                          the occurrence of the circumstances was unpredictable
                          at the time when the obliged party undertook to
                          perform the obligation.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">"Verification"</span>{" "}
                          shall mean the process or act of checking and
                          potentially confirming the truth and validity of
                          information provided by Client to TENX PRIME. Such
                          information concern Client's background, personal (and
                          corporate, if applicable) and account details and are
                          verified by requested documentation, as described
                          herein and in the Contract. Without due and successful
                          Verification, the Contract cannot be entered into.
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <span className="strong-content">
                            "Event of Default"
                          </span>{" "}
                          shall mean either one of the events of default
                          described under the Article XV. of these Terms and
                          Conditions and/or an action or circumstance that
                          causes a lender to demand full repayment of an
                          outstanding balance sooner than it was originally due.
                        </Typography>
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    II. General Statements and Provisions
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        These Terms and Conditions govern the rights and
                        obligations of the Client and TENX PRIME arising from
                        the Contract. As agreed in the account application, the
                        Terms and Conditions are an integral part of it and as a
                        consequence both parties hereby agree and declare that
                        they shall respect the rights of the other party and
                        perform their own respective obligations duly, properly
                        and with reasonably expectable care. Unless stipulated
                        otherwise herein, in the Contract or in the other
                        documentation governing the legal relationship between
                        TENX PRIME and Client, or unless agreed otherwise upon
                        the parties, the rights must be respected and
                        obligations performed since the Contract is entered into
                        and until the Contract is terminated with respect to
                        conditions of terminations stated herein and in the
                        Contract.
                      </li>
                      <li className="General">
                        Any time in this document that the term "Contract" is
                        referred to, unless stated otherwise such referral also
                        includes other documents, forming an integral part of
                        the Contract and are listed in the body of the Contract.
                      </li>
                      <li className="General">
                        In this document depending on the context masculine
                        might include feminine and neuter, singular includes
                        plural and vice versa.
                      </li>
                      <li className="General">
                        TENX PRIME reserves the right to modify these Terms and
                        Conditions unilaterally. No written amendment is
                        required for such modification. TENX PRIME undertakes
                        that these changes do not cause a fundamental change or
                        deterioration of status of a client. Any such change can
                        arise only with regard to the legitimate interests of
                        the counterparties. Unilateral change becomes effective
                        when published. Any potential fundamental change shall
                        be done in writing.
                      </li>
                      <li className="General">
                        Both parties hereby represent and warrant that when
                        dealing with the other party concerning any matter
                        arising out of the Contract, all the information they
                        provide shall be truthful and their acts and statements
                        shall be in full accordance with the applicable law
                        agreed upon in Article XIX. of this document.
                      </li>
                      <li className="General">
                        The Client hereby represents and declares, that the
                        entire Terms & Conditions were clear and understandable
                        to them, were introduced to them, they read it
                        carefully, were agreed thereto and became bound by its
                        provisions
                      </li>
                      <li className="General">
                        The Client further represents and declares that by
                        entering into this Contract and the Terms and Conditions
                        he becomes bound by the same. This document is an
                        expression of their free, serious and definite will, not
                        concluded under any adverse impacts or disadvantageous
                        circumstances.
                      </li>
                      <li className="General">
                        TENX PRIME reserves the right to assign its obligations
                        hereunder to any Third Party without any consent of the
                        Client. Client cannot assign any rights or obligations
                        under this Contract to any Third Party without a written
                        consent of TENX PRIME.
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    III. Agents, Community Property and Joint Account
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        In the event an Agent is acting on behalf of Client,
                        TENX PRIME shall request all information and documents
                        necessary for the identity Verification of such Agent
                        (see Article IV. point 6., if the Agent is an individual
                        or Article IV. point 7., if the Agent is a legal entity)
                        and rights of such Agent in respect for acting on behalf
                        of the Client in the following situations:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="atype">
                              While dealing with Agent for the first time;
                            </li>

                            <li className="atype">
                              While any of the provided data or document will
                              have expired;
                            </li>

                            <li className="atype">
                              Any other time TENX PRIME considers it necessary
                              for fulfilling the requirements set by its AML
                              Policy
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The provided documents must further provide satisfactory
                        legal base for Agent to execute proposed act; otherwise
                        TENX PRIME shall reject such act.
                      </li>
                      <li className="General">
                        In case the rights of the Agent in the event described
                        in point I. of this Article are based on a Power of
                        Attorney, TENX PRIME may, at its sole discretion,
                        require the signature of the Client as a principal on
                        the Power of Attorney to be authorized by a public
                        notary and the Power of Attorney to be apostilled and
                        super legalized.
                        <br />
                        <br />
                        In case that the rights of Agent in situation described
                        in point 1. of this Article are based on Power of
                        Attorney, the Power of Attorney must include:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="atype">
                              Detailed personal data of Client;
                            </li>

                            <li className="atype">
                              Detailed personal data of Agent;
                            </li>

                            <li className="atype">
                              Precisely defined scope of act(s), to which the
                              Agent is authorized;
                            </li>
                            <li className="atype">
                              Signature of Client as a principal.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        In case TENX PRIME concludes single Contract with more
                        than one person, having joint property (and bank
                        account, as applicable) and acting as a client, the
                        following conditions apply:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="atype">
                              TENX PRIME requires identification and
                              verification of the identity of both persons in
                              accordance with Article IV. point 6. of this
                              document;
                            </li>

                            <li className="atype">
                              TENX PRIME may require additional documentation
                              proving that the provided funds, Transaction
                              Account(s) or Transaction Credit Card(s) are part
                              of the joint property;
                            </li>

                            <li className="atype">
                              Each of the persons, owning the Joint Account,
                              shall be considered as a co-owner of Client
                              Account, as well as joint creditor and joint
                              debtor for the purposes of rights and obligations
                              arising out of the Contract;
                            </li>
                            <li className="atype">
                              Each of the co-owners of Joint Account has the
                              right, in general, to control the Client Account
                              and has a disposition right to the funds thereon.
                              In some cases, however, TENX PRIME has the right
                              to refuse or reject orders or proposals, made by
                              any of the co- owners, unless consent of the other
                              co-owner(s) is provided in writing;
                            </li>
                            <li className="atype">
                              Even if the communication is held with one of the
                              co-owners, the right to object against such
                              communication shall be granted to each of the
                              co-owner.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="atype">
                        The conditions set in point 4 above applies to the cases
                        of Joint Accounts, apart from letter e) accordingly.
                      </li>
                      <li className="atype">
                        Situation in point 1 above does not liberate the Client
                        or the Agent from the obligation to provide information
                        and documents which are considered necessary to identify
                        and verify the identity of Client in compliance with
                        this document.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    IV. Conclusion of Contract
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="atype">
                        The Contract is essential for the existence of legal
                        rights and obligations of parties to the Contract
                        arising thereof. Such rights and obligations come to
                        existence at the moment of conclusion of the Contract.
                        These Terms and Conditions are an integral part of the
                        Contract.
                      </li>
                      <li className="atype">
                        The Client hereby acknowledges that by the moment of
                        conclusion of this Contract, there are no legal rights
                        and obligations established between the Client and TENX
                        PRIME, unless expressly stated otherwise herein or in
                        the Contract.
                      </li>
                      <li className="atype">
                        The Contract is considered to be concluded when the
                        trading account is opened for the Client.
                      </li>
                      <li className="General">
                        The conclusion of Contract is considered as defective if
                        any party to the Contract was not aware thereof and was
                        not supposed to be reasonably expected to know such
                        fact, including but not limited to one or more of the
                        following situations:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="atype">
                              Provision of false, untrue information by the
                              other party or provision of information of
                              misleading nature in any other way by such party;
                            </li>

                            <li className="atype">
                              Missing true will of the other party to enter into
                              Contract;
                            </li>

                            <li className="atype">
                              The other party suffers lack of legal capacity to
                              enter into Contract at the time of its conclusion.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        Consequences of such defective conclusion of the
                        Contract shall be as described in Article XIV. point 6.
                        of this document.
                      </li>
                      <li className="General">
                        For the purpose of executing the process of
                        Verification, potential Client-individual, must fulfil
                        the following requirements within registration form and
                        My TENX PRIME:
                        <Box sx={{ pl: 4, pb: "10px" }}>
                          <ol type="a">
                            <li className="atype">
                              Choosing and entering Password for the access of a
                              potential Client Account;
                            </li>

                            <li className="atype">
                              Entering their personal data - full name,
                              residence address, nationality, date & place of
                              birth, telephone number and email address;
                            </li>

                            <li className="atype">
                              Stating whether they are a Politically Exposed
                              Person;
                            </li>
                            <li className="atype">
                              Stating whether they are FATCA reportable;
                            </li>
                            <li className="atype">
                              Filling in the Investment Questionnaire;
                            </li>
                            <li className="atype">
                              Uploading photocopies of Proof of Identity
                              documents within registration form or into My TENX
                              PRIME, which shall prove the truthfulness of
                              personal data entered by potential Client as
                              described under letter b), which have further been
                              issued by public authority and shall include a
                              photo of Client;
                            </li>
                            <li className="atype">
                              Uploading photocopy of Proof of Residence
                              documents within registration form or into My TENX
                              PRIME, which shall prove the truthfulness of
                              personal data entered by potential Client;
                            </li>
                            <li className="atype">
                              Choosing trading Platform.
                            </li>
                          </ol>
                        </Box>
                        An exception to the requirement stated under point 6. is
                        the Smart Onboarding System where the client is not
                        required to provide documentation or details under
                        letter e) and g) of this point, unless the Client
                        deposits over USD 1,000 cumulative amount at which
                        point, they are obliged to meet each requirement of
                        point 6.
                      </li>

                      <li className="General">
                        For the purpose of executing the process of
                        Verification, potential Client-entity (legal person),
                        shall fulfil the following requirements within My TENX
                        PRIME:
                        <Box sx={{ pl: 4, pb: "10px" }}>
                          <ol type="a">
                            <li className="atype">
                              Entering Password for the access of a potential
                              Client Account;
                            </li>

                            <li className="atype">
                              Entering company details: name, seat address, date
                              of incorporation, company ID, telephone number,
                              email address and nature of business;
                            </li>

                            <li className="atype">
                              Entering personal data of any Authorized Person,
                              acting on behalf of such potential Client - full
                              name, residence address, nationality and their
                              legal position;
                            </li>
                            <li className="atype">
                              Filling in an investment questionnaire;
                            </li>
                            <li className="atype">
                              Uploading photocopies of Proof of Identity and
                              Proof of Residence documents into My TENX PRIME,
                              which shall prove the truthfulness of personal
                              data of potential Client's Authorized Person as
                              described under letter c), which have further been
                              issued by public authority and shall include a
                              photo of the Authorized Person;
                            </li>
                            <li className="atype">
                              Uploading photocopy of the following documents
                              into My TENX PRIME
                            </li>
                            <Typography>
                              (Proof of existence, list of Authorized Persons
                              and list of owners together with distribution of
                              shares), which shall prove the truthfulness of
                              company details as described under letter b) and
                              which have further been issued by public
                              authority;
                            </Typography>
                            <li className="atype">
                              Choosing trading Platform(s);
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        For the purpose of executing the process of
                        Verification, any potential Client, depending on the
                        selected first deposit method, shall provide a photocopy
                        of following documentation:
                        <Box sx={{ pl: 4, pb: "10px" }}>
                          <ol type="a">
                            <li className="atype">
                              In the event of using credit card for the first
                              deposit, a document proving the number of such
                              credit card, that such credit card is valid and
                              held in the name of a potential Client, issued by
                              an authorized credit institution and connected to
                              such account opened with this institution; or
                            </li>
                            <li className="atype">
                              In the event of using wire transfer for the first
                              deposit, a document proving an existence and
                              number of account open with credit institution,
                              held in the name of a potential Client.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The Client hereby acknowledges and agrees that due to
                        TENX PRIME's AML Policy, each deposit must be made by
                        credit card or account held in the name of the Client
                        exclusively, while such credit card or account details
                        must be indicated in My TENX PRIME prior to making the
                        deposit, as described in point 8. of this Article.
                      </li>
                      <li className="General">
                        TENX PRIME shall examine the data, provided based under
                        points 6. to
                        <br />
                        <br />
                        9. of this Article, on regular basis.
                      </li>
                      <li className="General">
                        Potential Client hereby acknowledges that in the event
                        TENX PRIME does not consider the information or
                        documents provided by potential Client in compliance
                        with points
                        <br />
                        <br />
                        6. to 9. of this Article, in its sole discretion, as
                        satisfactory with regard to TENX PRIME AML Policy, TENX
                        PRIME may require additional information or documents
                        from such potential Client before the conclusion of this
                        Contract.
                      </li>
                      <li className="General">
                        If the potential Client fulfils the requirement
                        described in points 6. to 9. of this Article
                        successfully, TENX PRIME shall send a confirmation of
                        such fact to the Client within two working days via
                        email, provide the Client with full access to their
                        Client Account and allow Client to execute Transaction
                        Orders.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    V. Client Account
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        TENX PRIME hereby represents and warrants that while
                        dealing with any monetary funds or other assets of
                        Client, it shall act prudently and with due professional
                        care. Furthermore, TENX PRIME shall not disclose any
                        confidential information and facts related to Client
                        Account to third parties, unless it's:
                        <Box sx={{ pl: 4, pb: "10px" }}>
                          <ol type="a">
                            <li className="atype">
                              necessary for the performance of obligations under
                              this contract; or
                            </li>
                            <li className="atype">
                              necessary for the purposes described in Article
                              XII. Of this document or other actions required by
                              the authorities, administrative bodies or courts;
                              or
                            </li>
                            <li className="atype">
                              expressly permitted by the Client inwriting.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The information, documents and data provided by Client
                        in accordance with Article IV. points 6. or 7. are parts
                        of Client Account. The Client is obliged to inform TENX
                        PRIME about any changes in such information, and
                        documents of data. It is Client's obligation to provide
                        TENX PRIME with up-to-date information and support the
                        information with corresponding documents. If possible,
                        e. g. in cases including, but not limited to, expiration
                        of proof of residence or ID card, the Client is obliged
                        to fulfil this obligation prior to effectiveness of such
                        change, otherwise without due delay.
                      </li>
                      <li className="General">
                        As stated, and agreed by the parties in hereunder and
                        under the conditions specified herein, TENX PRIME shall
                        open, maintain and manage a Client Account for the
                        Client.
                      </li>
                      <li className="General">
                        The Client Account shall be maintained and all
                        operations concerning the Client Account shall be made
                        by both parties of the Contract via My TENX PRIME,
                        unless expressly stated herein or in the Contract.
                      </li>
                      <li className="General">
                        The Client Account is accessible through My TENX PRIME
                        after entering pre-set Login and Password by Client. The
                        Client hereby acknowledges and agrees that he is the
                        only person authorized and permitted to manage Client
                        Account in respect to entering Transaction Orders,
                        changing or updating personal data and in other way
                        perform rights and obligations arising from the Contract
                        and this document.
                      </li>
                      <li className="General">
                        Client Account is held in a Currency chosen by the
                        Client at the time of conclusion of this Contract. Full
                        range of Currencies, in which Client Account may be
                        held, depends on the current offer of TENX PRIME.
                      </li>
                      <li className="General">
                        No interest is accrued or debited in relation to the
                        funds and deposits made to Client Account.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that the funds deposited
                        by the client on the Client Account are kept in one or
                        more accounts held with a financial institution on
                        behalf of TENX PRIME. The selection of specific
                        financial institution, with which such accounts are
                        held, is solely within TENX PRIME's discretion. However,
                        such choice shall be done in accordance with Article V.
                        point 1.
                      </li>
                      <li className="General">
                        Client is obliged to notify TENX PRIME about any
                        suspicion or awareness of potential unauthorized use of
                        the Password to Client Account without delay. In such a
                        case TENX PRIME shall disable Client Account without
                        undue delay.
                      </li>
                      <li className="General">
                        The Client may request revision of any non-executed
                        transaction. Such revision may be carried out only on
                        the basis of justified reasons (so a reasonable concern
                        of incorrect calculation, non-execution etc.). However,
                        if the client's request proves to be inadequate and
                        unjustified, TENX PRIME may, at its own discretion,
                        charge $100 (one hundred U.S. dollars) per revision. In
                        the event such revision proves that such request of
                        client was justified and transaction non-executed, TENX
                        PRIME shall bear the costs of such revision.
                      </li>
                      <li className="General">
                        The client hereby acknowledges that TENX PRIME will have
                        the right to investigate and ban without undue delay any
                        account that has exhibited any suspicious trading
                        activity, especially in the matter of similar IP
                        address.
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    VI. Transaction Account and Credit Card
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Any credit card or account, from which the first deposit
                        to Client Account is successfully made, is authorized
                        automatically as Transaction Account/Transaction Credit
                        Card.
                      </li>
                      <li className="General">
                        To add a new Transaction Account or Transaction Credit
                        Card to the register in My TENX PRIME, Client must
                        provide documentation necessary for Verification of the
                        account/credit card, as described in Article IV. point
                        8. letter a) orb) of this document.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    VII. Funds
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        The funds deposited on Clients Account may be used by
                        TENX PRIME solely for the following purposes:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              Settlement of Transactions;
                            </li>
                            <li className="General">
                              Settlement of fees and commissions charged in
                              accordance with conditions set upby each Platform;
                            </li>
                            <li className="General">
                              Coverage of Transaction Margin;
                            </li>
                            <li className="General">Withdrawal of funds;</li>
                            <li className="General">
                              Conclusion of new Transactions;
                            </li>
                            <li className="General">
                              Collateral/security deposit.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that in case the amount
                        of funds on Client Account is not satisfactory for
                        settlement of all orders entered by Client, the order of
                        the list presented in point 1. reflects the priority for
                        usage of funds on the Client Account and prospective new
                        deposited funds.
                      </li>
                      <li className="General">
                        The Client further acknowledges and agrees to the
                        following terms and conditions concerning deposits of
                        funds:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              All deposits must only be made from Transaction
                              Account(s) or Transaction Credit Card(s),
                              otherwise they will be rejected by TENX PRIME;
                            </li>
                            <li className="General">
                              The deposits must be made and the corresponding
                              funds must be sent only in the Currency, in which
                              the Client Account is maintained;
                            </li>
                            <li className="General">
                              The deposited funds shall become available within
                              the Client Account after TENX PRIME receives a
                              confirmation from the bank of the funds being
                              credited to TENX PRIME's Account. When such a
                              confirmation is received, TENX PRIME shall make
                              funds accessible without undue delay.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The Client hereby acknowledges and agrees to the
                        following terms and conditions concerning withdrawals of
                        funds:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              A request for withdrawal can be filled only (1) in
                              writing by using a withdrawal form, which can be
                              found on TENX PRIME 's Website or (2) via My TENX
                              PRIME by entering corresponding information
                              specified there in;
                            </li>
                            <li className="General">
                              All withdrawals must only be made to Transaction
                              Account(s) or otherwise the request for withdrawal
                              will be rejected by TENX PRIME;
                            </li>
                            <li className="General">
                              The request shall further be rejected by TENX
                              PRIME in case that the requested amount exceeds
                              the minimum Transaction Margin required for
                              currently opened position. The request shall
                              further be
                            </li>
                            <li className="General">
                              Rejected by TENX PRIME in case that the person
                              placing the request is not duly authorized or
                              their authenticity is disputable;
                            </li>
                            <li className="General">
                              The withdrawals are executed and the corresponding
                              funds are sent to Transaction Account only in the
                              Currency, in which the Client Account is held;
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        TENX PRIME reserves the maximum period of five working
                        days, starting at the moment of placing a withdrawal
                        request by Client, before the funds are transferred to
                        Transaction Account of Client. The period serves for
                        Verification of person placing the request and the
                        amount of funds remaining on Client Account. The
                        withdrawal orders are thought to be executed by TENX
                        PRIME without undue delay.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that in the event where
                        TENX PRIME receives a request for refund or chargeback
                        of funds already credited to TENX PRIME 's Account, no
                        matter whether such request comes from the Client or a
                        financial institution, with which Client's Transaction
                        Account is opened, such request can only be satisfied up
                        to the amount of funds remaining and being available on
                        Client Account at the moment of delivery of the request
                        to TENX PRIME. TENX PRIME has no responsibility and
                        shall not refund or charge back any loss caused by
                        unsuccessful trading or spent on charges.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that any chargebacks or
                        refunds shall only be made to Transaction Account or via
                        Transaction Credit Card.
                      </li>
                      <li className="General">
                        In the certain occasions the Client shall be awarded
                        bonuses in form of Credit. This Credit shall be removed
                        anytime according to the decision of TENX PRIME. The
                        Client is not entitled to the legal claim of Credit
                        assignation. Detailed description shall be found in a
                        special terms and conditions of each bonus promotion.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that any deposit and
                        withdrawal requests from an account that has no prior
                        trading activities are not allowed, therefore adhering
                        to the Anti-Money Laundering Act (AMLA).
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    VII. Communication
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Unless stated otherwise, any communication between TENX
                        PRIME and Client, which related to this Contract and its
                        scope in any way, shall be done orally, by phone, email
                        or other electronic means including My TENX PRIME or by
                        correspondence.
                      </li>
                      <li className="General">
                        Not with standing point I. of this Article and unless
                        stated otherwise in this document, any amendments or
                        supplements to the Contract and related documents must
                        be made in writing, agreed upon by both parties and
                        attached to the Contract.
                      </li>
                      <li className="General">
                        Any communication from TENX PRIME shall not be deemed
                        effective unless served to the Client. Any communication
                        from the Client shall not be deemed effective unless
                        accepted by TENX PRIME.
                      </li>
                      <li className="General">
                        The language used in the Contract, related documents and
                        their amendments shall be the English language. Whenever
                        the Client is required to provide any document to TENX
                        PRIME, such document must be in English language, unless
                        required otherwise by TENX PRIME or tacitly accepted.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges and agrees that TENX
                        PRIME makes, saves and keeps records of all
                        communication as described in point 1. of this Article,
                        regardless whether such communication is made by phone,
                        in writing, via My TENX PRIME or in any other way. The
                        content of such communication shall be considered
                        confidential and serves only for the purpose of evidence
                        and to comply with TENX PRIME's AML Policy.
                      </li>
                      <li className="General">
                        Whenever a communication as described in point 1. of
                        this Article is carried out by email, the Client must
                        use only one dedicated email address.
                      </li>
                      <li className="General">
                        Actual contact information and details of TENX PRIME are
                        indicated and listed on TENX PRIME's website while TENX
                        PRIME has the right to change them in accordance with
                        other provisions of this document anytime without any
                        notice to the Client.
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    IX. Trading
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        The Client hereby acknowledges that for the purpose of
                        any Opening Transaction, the Client is obliged to
                        provide a Transaction Margin under the following
                        conditions:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              The required amount of funds used as Transaction
                              Margin shall be calculated as a percentage of the
                              nominal value of Transaction. Such percentage
                              varies depending on type of Financial Instrument
                              and its actual Exchange Rate, therefore might be
                              subject to frequent changes and the provided
                              Leverage;
                            </li>
                            <li className="General">
                              The Transaction Margin must be provided to the
                              Client in the Currency of Client Account;
                            </li>
                            <li className="General">
                              The funds used as Transaction Margin shall be
                              blocked during holding position and accordingly
                              shall not be available to the Client;
                            </li>
                            <li className="General">
                              TENX PRIME has the right to change the required
                              Transaction Margin. Client must be informed about
                              the fact without undue delay;
                            </li>
                            <li className="General">
                              Client is obliged to maintain the amount of funds
                              on Client Account higher than required Transaction
                              Margin for their opened positions. In case that
                              amount of funds on Client Account falls below the
                              required Transaction Margin for opened positions,
                              Client is obliged to deposit additional funds on
                              the Client Account or close one or more positions
                              in order to reduce the risk of falling below
                              required Transaction Margin without undue delay;
                            </li>
                            <li className="General">
                              Client hereby agrees that whenever the amount of
                              funds on Client Account falls below 50% of the
                              required Transaction Margin for opened positions,
                              TENX PRIME has the right to close one or more open
                              positions of the Client without Client's consent
                              in order to reduce sufficiently the required
                              Transaction Margin. Client shall be notified about
                              this fact without undue delay;
                            </li>
                            <li className="General">
                              TENX PRIME offers clients a negative balance
                              protection which does not charge loss exceeding
                              the margin when the set position cannot be closed
                              and the margin becomes negative in an irregular
                              situation such as concentration of market order.
                              Margin negative will be resolved within 7 business
                              days and the equity will return to "O". In the
                              event such balance is not brought back to O form
                              negative, the Client shall contact TENX PRIME;
                            </li>
                            <li className="General">
                              TENX PRIME reserves the right to apply changes to
                              and amend the leverage ratio (i.e., decrease or
                              increase the leverage ratio), at its sole
                              discretion and without any notification, case by
                              case on any account of the client as deemed
                              necessary by TENX PRIME.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        TENX PRIME shall provide the Client with systematic
                        Quotation of prices of Financial Instruments, providing
                        buying price, selling price and the number of Spreads of
                        each Financial Instrument. Current Spreads and prices
                        are passed to the Client through Trading System and
                        accessible on TENX PRIME's Website.
                      </li>
                      <li className="General">
                        TENX PRIME has the right to suspend Quotation and
                        trading on a specific Financial Instrument in the cases
                        including, but not limited to, suspension of trading on
                        an underlying instrument or force majeure as described
                        herein.
                      </li>
                      <li className="General">
                        TENX PRIME has further the right to modify Spreads
                        without any notice to the Client.
                      </li>
                      <li className="General">
                        The Client acknowledges and agrees that TENX PRIME uses
                        Market Execution method.
                      </li>
                      <li className="General">
                        TENX PRIME may at its sole discretion, while making
                        reasonable efforts for post- notification, alter, refuse
                        to transmit or execute any transaction or revoke an
                        executed transaction in particular in the following
                        cases:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              The transactions were executed by
                              arbitrage/exploitation of market failures, off
                              market rates or any other abusive technique as
                              determined by TENX PRIME;
                            </li>
                            <li className="General">
                              A technical problem withheld the transaction from
                              being executed as desired;
                            </li>
                            <li className="General">
                              A liquidity provider has cancelled or altered the
                              transaction with TENX PRIME; and/or
                            </li>
                            <li className="General">
                              The transaction covering was executed with the
                              liquidity provider. TENX PRIME is also entitled,
                              at any time and at its discretion, without giving
                              any notice or explanation to the Client, to
                              decline or refuse to transmit or arrange for the
                              execution of any Order or Request or Instruction
                              of the Client.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        A Transaction order can only be entered within the
                        trading Platform under the conditions set in Article IX.
                      </li>
                      <li className="General">
                        TENX PRIME has the right to refuse Opening Position in
                        its sole discretion and without any responsibility of
                        the amount of both actual damage and lost profit of the
                        Client.
                      </li>
                      <li className="General">
                        Unless stated otherwise herein or executed by TENX PRIME
                        without due diligence or inconsistently with the orders
                        of Client, Client has full responsibility of any
                        Transaction Order, other instruction or disposition
                        executed within Client Account.
                      </li>
                      <li className="General">
                        Client is able to place to the Trading System only
                        Transaction Orders, which are allowed by TENX PRIME.
                        Such Transaction Orders include, but are not limited to,
                        stop orders, limit orders or contingency orders. The
                        current offer of Transaction Orders allowed by TENX
                        PRIME can be changed by TENX PRIME without any notice to
                        Client.
                      </li>
                      <li className="General">
                        Any single Transaction Order may refer only to buying or
                        selling of one particular Financial Instrument.
                      </li>
                      <li className="General">
                        In order to achieve validity, any Transaction Order
                        shall contain at least name of Client and identification
                        of Client Account, type of Financial Instrument, date
                        and time of placement of order, volume of Financial
                        Instrument, type and number of an order. A valid
                        Transaction Order can only be modified before its
                        execution, unless stated otherwise herein.
                      </li>
                      <li className="General">
                        Client hereby acknowledges that any Transaction Order
                        shall be executed only when Client has sufficient amount
                        of funds on Client Account to establish Transaction
                        Margin, otherwise the Transaction Order will be
                        rejected.
                        <br />
                        <br />
                        Any Position Closing shall result in the termination of
                        all rights and obligations arising from such previously
                        open position, unless stated otherwise herein. Financial
                        result of Closing Position is settled by TENX PRIME on
                        the day of closing.
                      </li>
                      <li className="General">
                        In case of client uses arbitrage, or any other illegal
                        trading method or strategy , the company reserves a
                        right to cancel all orders of the Client , cancel the
                        Client’s profit associated with all closed orders ,
                        close all trading accounts of the Client and refuse
                        further provision of the service to the client , suspend
                        or ban client profile and trading accounts .
                        <br />
                        <br />
                        In exceptional cases, short-term orders or any order
                        considered abuse will be cancelled and we will cancel
                        all the orders including gained profit.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XI. Islamic Accounts
                  </Typography>
                  <Box sx={{ pl: 4 }}>
                    <ol>
                      <li className="General">
                        Islamic Accounts, also known as swap free accounts,
                        offer trading with instruments, offered by TENX PRIME,
                        in order to be in compliance with the Sharia Law, as
                        they imply no swap or rollover interest on overnight
                        positions. Islamic Account is completely swap free for
                        as long as the account is open and it should only be
                        requested and made available for traders who are
                        followers of this Islamic tradition. (Hereinafter
                        referred to only as the "Islamic Account").
                      </li>
                      <li className="General">
                        TENX PRIME reserves the right to request any proof of
                        religion or respective declaration with regard to
                        client's request for Islamic Account. By accepting these
                        terms & conditions for Islamic Accounts, client hereby
                        confirms that any potential disadvantage, arising out of
                        the swap-free accounts or in relation thereto, will be
                        considered as prerequisite for the purpose of compliance
                        with Sharia law and interpretation, and may not be
                        considered as any form of discrimination.
                      </li>
                      <li className="General">
                        Islamic Accounts can be open for both, the ECN type of
                        account as well as for the Standard type of account.
                      </li>
                      <li className="General">
                        Islamic Account will not be charged by swaps; however,
                        all other rules apply as described in the general Terms
                        and Conditions, available on TENX PRIME's Website and
                        special commissions applicable to Islamic Accounts.
                      </li>
                      <li className="General">
                        In case a Client has more real trading accounts, by
                        submitting an Islamic account request and following its
                        approval, all of the Client's trading accounts shall
                        have the Islamic status (no swaps applicable). A Client
                        cannot have both an Islamic and a non-Islamic account
                        with TENX PRIME at the same time.
                      </li>
                      <li className="General">
                        TENX PRIME reserves the right to decline an Islamic
                        request, without providing any explanation/justification
                        to the applicant for Islamic Account.
                      </li>
                      <li className="General">
                        TENX PRIME reserves the right to revoke the Swap-free
                        status granted to any real trading Account at any time
                        without being obliged to provide any explanation or
                        justification. Furthermore, in the event that TENX PRIME
                        shall detect any form of abuse, fraud, manipulation,
                        cashback arbitrage, carry trades, or other forms of
                        deceitful or fraudulent activity in regard to any
                        Swap-free Account of any Client, TENX PRIME reserves the
                        right, at any time to:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              Revoke the Swap-free status from any and all real
                              trading Accounts of such Client that have been
                              converted to a Swap-free trading Account;
                            </li>
                            <li className="General">
                              To correct and recover any un-accrued Swaps and
                              any related un accrued interest;
                            </li>
                            <li className="General">
                              Expenses and or costs pertaining to any and all of
                              such Client's swap- free trading Accounts during
                              the period for which such Accounts were converted
                              into Swap-free trading Accounts; and/or
                            </li>
                            <li className="General">
                              to close all trading Accounts of such Client with
                              TENX PRIME, nullify all trades carried out in such
                              Client's trading Accounts with us and cancel and
                              all profits or losses garnered in such Client's
                              trading Accounts with TENX PRIME. <br />
                              <br />
                              All actions described above may be implemented
                              with an immediate effect, without prior
                              notification.
                            </li>
                          </ol>
                        </Box>
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XII. Decisions of Authorities or Courts
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        The Client hereby acknowledges that TENX PRIME shall
                        follow and duly fulfil any and all requirements of
                        relevant financial and other authorities or courts and
                        perform any obligations imposed on TENX PRIME during the
                        period of performance of its business activities. In
                        case when such requirements or obligations are not
                        consistent with provisions of the Contract, the
                        requirements and obligations imposed by authorities
                        shall prevail and come first with no exceptions.
                      </li>
                      <li className="General">
                        In case TENX PRIME acts inconsistently with the
                        provisions of the Contract as a consequence of situation
                        described in point 1. of this Article, such acts are not
                        considered to be a breach of Contract and there is no
                        responsibility arising on the side of TENX PRIME for any
                        damage or loss caused to the Client.
                      </li>
                      <li className="General">
                        Upon request of a relevant financial authority TENX
                        PRIME may disable Client's Account. immediately and
                        without any prior notice.
                      </li>
                    </ol>
                  </Box>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XIII. Closing of Client Account
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Legitimate reasons for closing of Client Account
                        include, but are not limited to:
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">Request by Client;</li>
                            <li className="General">
                              Obligation imposed by authority as described in
                              Article XII. of this document;
                            </li>
                            <li className="General">
                              Suspicious activity on Client Account in respect
                              of identification of Client, origin of funds
                              deposited on Client Account or other matter
                              concerning TENX PRIME 's AML Policy;
                            </li>
                            <li className="General">
                              Reasonable suspicion of any breach of the Contract
                              made from the side of Client;
                            </li>
                            <li className="General">
                              Reasonable suspicion of any violence of applicable
                              law, rule or regulation made from the side of
                              Client;
                            </li>
                            <li className="General">
                              Inactivity of Client Account for at least three
                              months; Substantial burdening of Platform by
                              entering a very high amount of Transaction Orders
                              from the side of Client.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        TENX PRIME is obliged to explain to Client the reason of
                        closing the account anytime upon request.
                      </li>
                      <li className="General">
                        The Client hereby acknowledges that TENX PRIME has the
                        full right to disable Client’s account anytime without
                        prior notice to Client and at TENX PRIME 's sole
                        discretion.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    IV. Termination of Contract
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Upon an effective termination of the Contract the
                        following amounts to be paid by the Client become due
                        and payable:{" "}
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              All outstanding fees, commissions and charges;
                            </li>
                            <li className="General">
                              Any expenses arising from the termination of
                              Contract or Closing Position;
                            </li>
                            <li className="General">
                              Any other losses or expenses arising from the
                              Contract.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        The client hereby acknowledges that termination of
                        Contract shall not affect any Transaction previously
                        entered into by Client and shall not relieve any of the
                        parties from any obligation arising out of the Contract
                        and already existing at the time of effective
                        termination of Contract. TENX PRIME shall have the right
                        to cancel, unwind or relief the Contractual Parties from
                        any transaction which is based on a breach of Contract
                        or the applicable law.
                      </li>
                      <li className="General">
                        Each of the party can terminate the Contract with a
                        1-week (one week) written notice, while such period is
                        initiated at the moment such notice is effectively
                        delivered to the other party.
                      </li>
                      <li className="General">
                        Upon written consent of both parties, the Contract may
                        also be terminated with immediate effect or within a
                        different period than the one stated in point 3. of this
                        Article.
                      </li>
                      <li className="General">
                        In case of termination of the Contract in accordance
                        with the point 3. and 4. of this Article by the Client,
                        such termination may occur only if there are no open
                        positions on the Client Account. Otherwise, TENX PRIME
                        shall close all open positions on Client Account at the
                        moment the termination of Contract comes into effect.
                      </li>
                      <li className="General">
                        TENX PRIME has the right to terminate the Contract with
                        immediate effect in case the Client is in breach of any
                        of the provisions of the Contract, in case any
                        statements or representations, provided by Client, are
                        found to be false, untrue or misleading during the
                        effect of the Contract or whenever a Conflict of
                        Interests arises.
                      </li>
                      <li className="General">
                        In case of death of Client - individual or in the event
                        of liquidation or insolvency of Client - entity, this
                        Agreement shall terminate automatically on the next
                        business day after the receipt of an official evidence
                        of the fact above by TENX PRIME.
                      </li>
                      <li className="General">
                        TENX PRIME has the right to terminate the Contract with
                        immediate effect if the country of Client's residence
                        appears on Financial Action Task Force sanction list.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XV. Default
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Each of the following constitutes an "Event of Default":
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              The failure of the Client to provide any initial
                              Margin and/or hedged Margin, or any other amount
                              due under the Contract;
                            </li>
                            <li className="General">
                              The failure of the Client to perform any pecuniary
                              obligation owed to TENX PRIME.
                            </li>
                            <li className="General">
                              If an application is made in respect of the Client
                              pursuant to the relevant Bankruptcy Laws, as
                              amended or any equivalent act in another
                              Jurisdiction (if the Client is an individual), if
                              a partnership, in respect of one or more of the
                              partners, or if a company, a receiver, trustee,
                              administrative receiver or similar officer is
                              appointed, or if the Client makes an arrangement
                              or composition with the Client's creditors or any
                              procedure which is similar or analogous to any of
                              the above is commenced in respect of the Client;
                            </li>
                            <li className="General">
                              Where any representation or warranty made by the
                              Client is/or becomes false, untrue or invalid;
                            </li>
                            <li className="General">
                              The Client is unable to pay the Client's debts
                              when they fall due;
                            </li>
                            <li className="General">
                              The Client (if the Client is an individual) dies
                              or is declared absent or becomes of unsound mind;
                            </li>
                            <li className="General">
                              Any other circumstance where TENX PRIME reasonably
                              believes that it is necessary or desirable to take
                              any action set out in the following paragraph;
                            </li>
                            <li className="General">
                              The Client involves TENX PRIME in any type of
                              fraudulent or illegal activity;
                            </li>
                            <li className="General">
                              An action set out in the following paragraph is
                              required by a competent regulatory authority or
                              body or court;
                            </li>
                            <li className="General">
                              In cases of material violation by the Client of
                              the requirements established by relevant
                              legislation, such materiality determined in good
                              faith by TENX PRIME;
                            </li>
                            <li className="General">
                              If TENX PRIME suspects that the Client is engaged
                              into money laundering activities or terrorist
                              financing or other criminal activities.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        If an Event of Default occurs TENX PRIME may, at its
                        sole discretion, at any time and without prior Written
                        Notice, take one or more of the following actions:{" "}
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              Terminate this Agreement without notice, granting
                              the right to perform any action specified in
                              Article "Termination of Contract" by TENX PRIME;
                            </li>
                            <li className="General">
                              Combine any Client Accounts, consolidate the
                              Balances in such Client
                            </li>
                            <li className="General">
                              Accounts and to offset those Balances;
                            </li>
                            <li className="General">
                              Close the Client Account;
                            </li>
                            <li className="General">
                              Cease to grant the access to TENX PRIME 's Online
                              Trading System to the Client; Convert any
                              currency;
                            </li>
                            <li className="General">
                              Suspend or freeze or close any open positions or
                              reject Orders;
                            </li>
                            <li className="General">
                              Refuse to accept Client Orders;
                            </li>
                            <li className="General">
                              Refuse to open new Client Accounts for the Client;
                            </li>
                            <li className="General">
                              Cancel, unwind or relief the Parties to the
                              Contract from any executed transaction.
                            </li>
                          </ol>
                        </Box>
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XVI. Risk Acknowledgement
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Client acknowledges and understands that trading and
                        investment on foreign exchange is highly speculative,
                        involves an extreme degree of risk, and is generally
                        appropriate only for persons who can assume risk of loss
                        in excess of their margin deposit.
                      </li>
                      <li className="General">
                        Client further understands that because of the low
                        Transaction Margin and high Leverage normally available
                        in foreign currency trading, price changes in foreign
                        currency Contracts may result in significant losses.
                        Such losses may substantially exceed Client's investment
                        and Transaction Margin deposit. Any profit or loss
                        arising as a result of a fluctuation in the exchange
                        rate affecting trading Currency shall be borne by the
                        Client in full.
                      </li>
                      <li className="General">
                        Client warrants that the Client is willing and able,
                        financially and otherwise, to assume the risk of foreign
                        currency trading, and in consideration of TENX PRIME
                        carrying Client Account, Client agrees not to hold TENX
                        PRIME and its Technology Providers responsible for
                        losses incurred through following its trading
                        recommendations or suggestions or those of its
                        employees, agents or representatives.
                      </li>
                      <li className="General">
                        Client hereby acknowledges that any guarantee of profit
                        or loss clearing cannot be given and it is impossible to
                        predict performance in foreign currency trading. Client
                        acknowledges that he has received no such guarantees
                        from TENX PRIME or from any of its employees,
                        representatives or any Introducing Partner and has not
                        entered into this Contract in consideration of any such
                        guarantees or similar representations.
                      </li>
                      <li className="General">
                        All transactions effected for Client Account and all
                        fluctuations in the market prices of the Contracts
                        carried in Client Account are at Client's risk, and
                        Client shall be solely liable therefore under all
                        circumstances. Should Client's account end in debit,
                        Client warrants that the Client will pay TENX PRIME the
                        amount due.
                      </li>
                      <li className="General">
                        TENX PRIME shall not be held responsible for any delays
                        or partial or total failures in any online (electronic)
                        trading platforms or any communications facility or
                        other causes beyond TENX PRIME 's reasonable direct
                        control.
                      </li>
                      <li className="General">
                        The Client understands and recognizes that the
                        transactions to be conducted pursuant to this Contract
                        are not conducted on a regulated market or exchange
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XVII. Indemnification
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        The Client agrees to defend, indemnify and hold TENX
                        PRIME, its Technology Providers, affiliates, and their
                        respective employees, agents, successors and assigns
                        harmless from and against any and all liabilities,
                        losses, damages, costs and expenses, including
                        attorney's fees and other legal expenses, incurred by
                        TENX PRIME arising out of the following:{" "}
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              Client's failure to perform their obligations in
                              full arising out of the Contract properly and on
                              time;
                            </li>
                            <li className="General">
                              In case that any of the representations and
                              warranties made by Client herein or at any time
                              are found to be untrue or incorrect;
                            </li>
                            <li className="General">
                              Violation by Client of any applicable law, rule or
                              regulation;
                            </li>
                            <li className="General">
                              Gross negligence or any wilful misconduct by
                              Client.
                            </li>
                          </ol>
                        </Box>
                      </li>
                      <li className="General">
                        Unless expressly stated herein, Client also agrees to
                        pay all damages, costs and expenses, including
                        attorney's fees, incurred by TENX PRIME in the
                        enforcement of any of the provisions of the Contract or
                        any other agreements concluded between TENX PRIME and
                        Client and any Transaction executed based on the
                        Contract, to TENX PRIME without delay. The Client’s
                        obligation stated in this point arises upon the
                        incurrence of the said costs.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XVII. Data protection
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        TENX PRIME is committed to protect all personal
                        information of the client, of which it becomes aware in
                        connection with the business cooperation between the
                        client and TENX PRIME. In this respect, TENX PRIME is
                        governed by the applicable laws, business practices and
                        good manners.
                      </li>
                      <li className="General">
                        This is not in conflict with provisions of Article XVII.
                        point I. when TENX PRIME provides client's e-mail
                        address to relevant Introducing Partner to ensure fast
                        communication.
                      </li>
                      <li className="General">
                        TENX PRIME collects personal and non-personal
                        information about the client for business and marketing
                        purposes. Such information shall be shared with
                        affiliates and business partners to transact business
                        effectively and the Client gives TENX PRIME express
                        permission to do so.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XIX. Jursidiction and Governing Law
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        An applicable substantive law to any dispute,
                        controversy or claim arising out of or relating to this
                        contract's Payment Clearing Services, the breach,
                        termination or invalidity thereof, shall be the Laws of
                        St Vincent & Grenadines. Investment Services in relation
                        to Financial Instruments shall be governed by the laws
                        of SVG.
                      </li>
                      <li className="General">
                        All disputes arising out of or in connection with the
                        actual Contract shall be finally settled under the Rules
                        of Arbitration of the International Chamber of Commerce
                        by three arbitrators appointed in accordance with the
                        said Rules. The seat of arbitration shall be Wien.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XX. Representations and Guarantees
                  </Typography>
                  <Typography>
                    Apart from other representations made by Client herein, the
                    Client further represents and warrants that:
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        If the Client is an individual, they are of sound mind,
                        legal age and of full legal capacity to enter into the
                        Contract;
                      </li>
                      <li className="General">
                        If the Client is a legal person, it is duly organized,
                        constituted and validly existing under the applicable
                        laws of the jurisdiction in which the Client is
                        constituted and each individual dealing with TENX PRIME
                        in any matter concerning the Contract is duly authorized
                        by the Client; Transaction Orders shall not be
                        recognized or enforceable.
                      </li>
                      <li className="General">
                        The Contract and its execution shall not violate any
                        law, regulation, by law or policy applicable to the
                        Client or be inconsistent therewith;
                      </li>
                      <li className="General">
                        Any information, documents or data provided by the
                        Client while entering into Contract and any time during
                        the validity and effectiveness of the Contract are
                        accurate, truthful and not misleading in any formal or
                        material respect;
                      </li>
                      <li className="General">
                        Client is willing and financially able to sustain any
                        loss of funds resulting from Transactions and any
                        services provided under the Contract, unless such loss
                        is caused by violation of applicable law or provisions
                        of the Contract, gross negligence or wilful misconduct
                        from the side of TENX PRIME;
                      </li>
                      <li className="General">
                        Client is a sole beneficial owner of all funds
                        transferred under the Contract and that such funds do
                        not derive directly or indirectly from any criminal
                        activity under the applicable laws. The Client has not
                        granted and will not grant any security interest in
                        Client Account with TENX PRIME to any person except TENX
                        PRIME without prior written consent of TENX PRIME.
                      </li>
                      <li className="General">
                        Before the conclusion of the Contract, the Client hereby
                        represents that they have read carefully and fully
                        agreed not only with the Contract and documents forming
                        an integral part of it, but also other documents
                        relevant for the performance of obligation under the
                        contract including, but not limited to TENX PRIME 's AML
                        Policy statement, list of contacts, risk disclaimer or
                        current trading conditions. All of the documents can be
                        accessed through the TENX PRIME 's Website.
                      </li>
                      <li className="General">
                        Before the conclusion of the Contract, the Client had
                        the opportunity to conduct simulated trading using TENX
                        PRIME 's demo trading Platform for a period that has
                        allowed the Client to develop a full understanding of
                        the Trading System and principles of trading.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XXI. MARKET Data
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        Thet Client hereby represents and warrants to TENX PRIME
                        that:{" "}
                        <Box sx={{ pl: 4 }}>
                          <ol>
                            <li className="General">
                              It will limit its use of Market Data solely for
                              the Internal Use (as defined below) of Client.
                              "Internal Use" shall mean access to and use of
                              Market Data for performance of research and
                              analysis, preparation of hardcopy research
                              documents and reports and for other data
                              processing use, analysis and distribution to the
                              Client (if an individual) or within Client's own
                              organization (if an entity) but not for
                              redistribution of, or the provision of access to,
                              Market Data to any third-party including but not
                              limited to any clients or customers of Client or
                              to any other non-Client persons or entities. A
                              Client-entity shall advise each of its employees
                              having access to Market Data of the proprietary
                              nature to treat such information pursuant to these
                              Terms & Conditions. Client shall not make Market
                              Data provided to them by TENX PRIME available to
                              any other Person;
                            </li>
                            <li className="General">
                              Client shall display the Market Data within a
                              Closed Environment only to third-party providers,
                              they entered into a legal relationship based on
                              any respective license or any other agreement or
                              arrangement, relating to the use, display and/or
                              redistribution, as applicable, of such Market
                              Data, while the Client agrees that they maintain
                              all such licenses, agreements and arrangements in
                              full force and effect for so long as it receives
                              the Market Data pursuant to this Agreement.
                            </li>
                          </ol>
                        </Box>
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XXII. Technical Issues
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        TENX PRIME bears no responsibility for any loss that
                        arises as a result of a system failure, including but
                        not limited to:{" "}
                        <Box sx={{ pl: 4 }}>
                          <ol type="a">
                            <li className="General">
                              Hardware or software failure, malfunction or
                              misuse either on the client's side or TENX PRIME
                              's or both;
                            </li>
                            <li className="General">
                              Poor or no internet connection either on the
                              client's side or the TENX PRIME 'or both;
                            </li>
                            <li className="General">
                              Incorrect settings in client terminal; and
                            </li>
                            <li className="General">
                              Delayed updates of client terminal.
                            </li>
                          </ol>
                        </Box>
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XXIII. Pending/Rejected Deposits
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        TENX PRIME shall inform its client about pending deposit
                        (received, but not credited to trading account yet)
                        within l Business Day, if there are not issues
                        (suspicious transaction, owner etc.) that would result
                        in deeper compliance assessment.
                      </li>
                      <li className="General">
                        Client shall provide relevant proof of deposit to TENX
                        PRIME, if requested by TENX PRIME.
                      </li>
                      <li className="General">
                        In the event of not resolving the pending deposit in 20
                        calendar days pursuant to point 1, such funds shall be
                        returned to sender.
                      </li>
                    </ol>
                  </Box>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    XXIV. Final Provisions
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol>
                      <li className="General">
                        In the event any complaints or claims against TENX PRIME
                        arise to the Client, such complaints or claims must be
                        submitted to any of authorized employees of TENX PRIME
                        in written form and no later than three calendar days in
                        case the claim or complaint concerns a Transaction or
                        Transaction Order, and no later than fifteen calendar
                        days in case the claim or complaint concerns Investment
                        Services provided by TENX PRIME. TENX PRIME has no
                        obligation of handling claims and complaints submitted
                        after these periods.
                      </li>
                      <li className="General">
                        Both parties hereby agree that the Contract and this
                        document can only be amended in writing. This rule shall
                        not apply to situations described in Article II. point 4
                        of this document.
                      </li>
                      <li className="General">
                        Unless stated otherwise herein, oral amendments,
                        agreements or instructions or Transaction Orders shall
                        not be recognized or enforceable.
                      </li>
                      <li className="General">
                        Client understands, acknowledges and agrees that TENX
                        PRIME may amend these Terms & Conditions at any time. To
                        meet the requirement of a written form, TENX PRIME shall
                        provide notice to Client of any such amendment or change
                        by sending an email message to Client and by posting the
                        amendment of these Terms & Conditions on TENX PRIME 's
                        Website. Client agrees to be bound by the terms of any
                        such amendment.
                      </li>
                      <li className="General">
                        In cases of force majeure, i.e., in situations beyond
                        the control of any of the parties, preventing them from
                        meeting their obligations under these Terms &
                        Conditions, no responsibility shall be taken for any
                        harmful consequences or losses, incurred by any party,
                        arising thereof.
                      </li>
                      <li className="General">
                        There is the possibility that certain ways of trading
                        are capable to incur damage, unjust enrichment etc. at
                        expense of TENX PRIME. Such situations could be caused
                        by wilful usage of technical errors or defects,
                        shuffling ways of trading, churning, unfair competitive
                        practices etc. Similar situations, but not limited to,
                        could arise in cases when any method of trading is
                        practiced in disproportionate or aggressive way (for
                        example in the use of a high-frequency trading and/or
                        latency arbitrage). The above trading strategies are
                        considered as abusive to TENX PRIME and remain
                        prohibited. TENX PRIME hereby reserves the right to
                        withhold performance of contractual obligations
                        (including payment of fees etc.) in the case where any
                        abusive trading is detected. It is in the sole
                        discretion of TENX PRIME to determine the occurrence of
                        abusive trading.
                      </li>
                      <li className="General">
                        Any invalid or unenforceable provision of the Contract
                        shall not affect any other provision hereunder and the
                        remainder of the Contract shall be valid and enforceable
                        to full extent permitted by law and the valid provisions
                        of the Contract.
                      </li>
                      <li className="General">
                        If any provision of the Contract is held to be invalid
                        or unenforceable by any court of competent jurisdiction,
                        the parties agree that the court may modify or amend
                        such provision to allow its enforcement to the maximum
                        extent permitted under the law. In case of inconsistency
                        between the provisions of Terms and Conditions and other
                        parts of the Contract, the Terms and Conditions shall
                        prevail.
                      </li>
                      <li className="General">
                        The articles, points, article titles and headings
                        contained in this Contract are inserted as matter of
                        convenience and for ease of reference only and shall be
                        disregarded for all other purposes, including the
                        construction or enforcement of this Contract or any of
                        its provisions.
                      </li>
                      <li className="General">
                        After reading the Contract, the Parties proclaim and
                        agree that they understand the Contract and its
                        provision, that this Contract represents a true
                        expression of their free, open, serious and error-free
                        will; and that is based on true facts known to the
                        parties at the date of conclusion of this Contract.
                      </li>
                    </ol>
                  </Box>
                </Box>
              </Modal>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              sx={{
                color: "white",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={handleOpen6}>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize=".8rem"
                  sx={{
                    color: "white",
                    textAlign: "left",
                  }}
                >
                  AML policy
                </Typography>
              </Button>
              <Modal
                open={open6}
                onClose={handleClose6}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="letterHead"></div>
                  <Typography id="modal-modal-description" sx={{ pt: "20px" }}>
                    <span className="strong-content"> Money Laundering - </span>{" "}
                    process of converting funds, received from illegal
                    activities (such as fraud, corruption, terrorism, etc.),
                    into other funds or investments which look legitimate to
                    hide or distort real source of funds.
                  </Typography>
                  <br />
                  <Typography id="modal-modal-description">
                    <span className="strong-content">
                      {" "}
                      The process of money laundering can be branched into the 3
                      following stages:
                    </span>{" "}
                  </Typography>
                  <Box sx={{ pl: 3 }}>
                    <ol type="123">
                      <li>
                        <Typography id="modal-modal-description">
                          <span className="strong-content"> Placement</span>
                          <br />
                          <br />
                          During this stage funds are converted into financial
                          instruments like checks, bank accounts, money
                          transfers or can be used for purchasing high-value
                          goods that can be resold. They can also be physically
                          deposited into banks and non-bank institutions (e.g.,
                          currency exchangers). To elude suspicion by the
                          company, launderers will make a number of deposits
                          instead of depositing the whole sum at once, this act
                          is called smurfing.
                        </Typography>
                      </li>

                      <li>
                        <Typography id="modal-modal-description">
                          <span className="strong-content"> Layering</span>
                          <br />
                          <br />
                          Funds get back into circulation as legitimate to
                          purchase goods and services.
                        </Typography>
                      </li>

                      <li>
                        <Typography id="modal-modal-description">
                          <span className="strong-content"> Integration</span>
                          <br />
                          <br />
                          During this stage funds are converted into financial
                          instruments like checks, bank accounts, money
                          transfers or can be used for purchasing high-value
                          goods that can be resold. They can also be physically
                          deposited into banks and non-bank institutions (e.g.,
                          currency exchangers). To elude suspicion by the
                          company, launderers will make a number of deposits
                          instead of depositing the whole sum at once, this act
                          is called smurfing.
                          <br />
                          <br />
                          <span className="strong-content"> AML Policy</span>
                          <br />
                          <br />
                          <span className="strong-content"> TenX Prime </span>
                          (hereinafter referred to as the "Company"), like the
                          majority of companies providing services on the
                          financial market, adheres to principles of Anti-Money
                          Laundering and actively prevents any actions that aim
                          or facilitate the process of legalization of illegally
                          gained funds. AML policy means preventing the use of
                          the Company's services by criminals, with the aim of
                          money laundering, terrorist financing or other
                          criminal activity.
                          <br />
                          <br />
                          For this purpose, a strict policy on the detection,
                          prevention and warning the corresponding bodies of any
                          suspicious activities was introduced by the Company.
                          Moreover, the Company has no right to report Clients
                          that the law enforcement bodies are informed on their
                          activity. Complex electronic system for identifying
                          every Company’s Client and conducting a detailed
                          history of all operations was introduced as well. To
                          prevent money laundering, the Company neither accepts
                          nor pays cash under any circumstances. The Company
                          reserves the right to suspend any Client’s operation,
                          that can be regarded as illegal or, may be related to
                          money laundering in the opinion of staff.
                          <br />
                          <br />
                          <span className="strong-content">
                            {" "}
                            Company's Procedures
                          </span>
                          <br />
                          <br />
                          The Company will make sure that it is dealing with a
                          real person or legal entity. The Company also performs
                          all the required measures in accordance with an
                          applicable law and regulations, issued by the monetary
                          authorities. AML policy is being fulfilled within the
                          Company by means of the following:
                        </Typography>
                        <Box sx={{ pl: 4 }}>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            - Knowing the customer’s policy and due diligence
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            - Monitoring of client's activity
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            - Record keeping
                          </Typography>
                        </Box>

                        <br />
                        <Typography id="modal-modal-description">
                          <span className="strong-content">
                            Know Your Customer and Due Diligence
                          </span>
                          <br />
                          <br />
                          Due to the Company's commitment to the AML and KYC
                          policies, each Client of the Company has to finish the
                          verification procedure. Before the Company starts any
                          cooperation with the Client, the Company ensures that
                          satisfactory evidence has been produced or such other
                          measures are taken as will produce satisfactory
                          evidence of the identity of any customer or
                          counterparty. The Company applies heightened scrutiny
                          to Clients, who are residents of the countries,
                          identified by credible sources as countries, having
                          inadequate AML standards or may represent high risk
                          for crime and corruption and to beneficial owners who
                          resides in and whose funds are sourced from named
                          countries.
                          <br />
                          <br />
                          <span className="strong-content">
                            {" "}
                            Indivdiual Clients
                          </span>
                          <br />
                          <br />
                          During the process of registration, each Client need
                          to provide personal information, specifically: full
                          name, date of birth, country of residence, complete
                          address, including phone number and city code. In
                          order to meet the Company's requirements for KYC and
                          identity verification and to confirm the indicated
                          information. Clients who are natural persons should
                          provide the following information upon the Company's
                          request:
                        </Typography>
                        <Box sx={{ pl: 4 }}>
                          <ul>
                            <li className="General">
                              A high-resolution copy of the government-issued
                              identification document, which should contain:
                              full name, date of birth, photo and citizenship,
                              and also, where applicable: confirmation of the
                              document validity (issue and/or expiry date),
                              holder's signature. Such identification document
                              might be a passport, national ID, driver's license
                              or similar document. The indicated documents must
                              be valid at least 6 months from the filing date.
                              The company reserves the right to request
                              certified copies of the indicated documents and
                              such certification must be valid at least 6 months
                              from the filing date. The company reserves the
                              right to request a second form of the
                              identification document.
                            </li>
                            <li className="General">
                              A high-resolution copy of a receipt of utility
                              services payment (gas, water, electricity or
                              other) or bank statement, containing full client’s
                              name and the actual place of residence. These
                              documents should not be older than 3 months from
                              the date of filing.
                            </li>
                          </ul>
                        </Box>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          When accepting new Clients during the verification
                          process and documents review the Company reserves the
                          right to apply other requirements and procedures for
                          the Client's identification. Such procedures are to be
                          determined at the Company's sole discretion and
                          subject to variations depending on the country of
                          residence of the Client and can include but not
                          limited to:
                        </Typography>
                        <Box sx={{ pl: 4 }}>
                          <ul>
                            <li className="General">
                              The requirement to verify the bank card used to
                              deposit/withdraw funds;
                            </li>
                            <li className="General">
                              The requirement to provide a source of funds,
                              source of wealth and proof of funds being
                              deposited in a form and manner as the Company
                              finds appropriate.
                            </li>
                          </ul>
                        </Box>
                        <Typography>
                          <br />
                          <br />
                          <span className="strong-content">
                            {" "}
                            Corporate Clients
                          </span>
                          <br />
                          <br />
                          In case the applicant company is listed on a
                          recognised or approved stock exchange or where there
                          is independent evidence to show that the applicant is
                          a wholly owned subsidiary or subsidiary under the
                          control of such a company, no further steps of
                          identity verification will normally be required. In
                          case the company is unquoted and none of the principal
                          directors or shareholders already has an account with
                          the Company, the official provides the following
                          documents to adhere the requirements of KYC:
                        </Typography>
                      </li>
                    </ol>
                  </Box>
                  <Box sx={{ pl: 5 }}>
                    <ul>
                      <li className="General">
                        A high-resolution copy of the certificate of
                        incorporation/certificate of registration.
                      </li>
                      <li className="General">
                        Extract from Commercial Register, or equivalent
                        document, evidencing the registration of corporate acts
                        and amendments.
                      </li>
                      <li className="General">
                        KYC documents of all officers, directors and beneficial
                        owners of the corporate entity.
                      </li>
                      <li className="General">
                        A high-resolution copy of Memorandum and Articles of
                        Association or equivalent documents duly recorded with
                        the competent registry.
                      </li>
                      <li className="General">
                        Evidence of the company's registered address; and the
                        list of shareholders and directors.
                      </li>
                      <li className="General">
                        Description and nature of business (including date of
                        commencement of business, products or services provided,
                        location of principal business).
                      </li>
                    </ul>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      This procedure is performed to establish the identity of
                      the Client and to help the Company know/understand Clients
                      and their financial dealings to be able to provide the
                      best services of online trading.
                    </Typography>
                    <br />
                  </Box>
                  <Typography sx={{ pl: 3 }}>
                    <br />
                    <span className="strong-content">
                      {" "}
                      Monitoring of Client Activity
                    </span>
                    <br />
                    <br />
                    In addition to gathering information from the Clients, the
                    Company continues to monitor the activity of every Client to
                    identify and prevent any suspicious transactions. A
                    suspicious transaction is known as a transaction that is
                    inconsistent with the Client's legitimate business or usual
                    Client's transaction history known from the Client activity
                    monitoring. The Company has implemented the system of
                    monitoring the named transactions (both automatic and, if
                    needed manual) to prevent using the Company's services by
                    the criminals.
                  </Typography>
                  <Typography sx={{ pl: 3 }}>
                    <br />
                    <span className="strong-content">
                      {" "}
                      Deposit and Withdrawal Requirements
                    </span>
                    <br />
                    <br />
                    All the Clients’ operations to deposit and withdraw funds
                    have the following requirements: In the case of bank
                    transfers or transfers from the bank card, the name
                    indicated during the registration must match the name of the
                    owner of the account/bank card. It is possible to withdraw
                    funds from the account via bank transfer only through the
                    same bank and the same account which you used for
                    depositing.
                  </Typography>
                  <Box sx={{ pl: 5 }}>
                    <ul>
                      <li className="General">
                        In case of using electronic payment systems, withdraw
                        funds from the trading account is possible only on the
                        system and the account used for depositing;
                      </li>
                      <li className="General">
                        If the account was credited in the way that cannot be
                        used for funds withdrawal, the funds may be withdrawn to
                        a bank account of the client or any other way may be
                        used, as agreed with the Company with the help of which
                        the Company is able to prove the identity of the account
                        owner;
                      </li>
                      <li className="General">
                        If the account has been credited with funds through
                        various payment systems, funds withdrawal shall be made
                        on a pro rata basis commensurate to the size of each
                        deposit. Any profit gained can be transferred to any
                        account from which the deposit arrived as Iong as such
                        transfer is possible.
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Modal>
            </Grid>
          </Grid>

          <Copyright />
        </Container>
      </Box>
    </Box>
  );
};
export default Footer;
