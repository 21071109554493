import { Container, Box, Typography, Button } from "@mui/material";
import "./HeroBanner.css";
import NonmobileUse from "./components/nonmobileuse";
import WeOffer from "../AccountType/accounttype";
import { height } from "@mui/system";

const HeroBanner = () => {
  return (
    <>
      <div className="Empty-Banner">
        <Container maxWidth="lg" sx={{ pt: 4 }}>
          <Typography
            variant="h1"
            color="orange"
            className="H-title"
            fontSize={{
              xs: "2.7rem",
              sm: "3rem",
              md: "3.5rem",
              lg: "5rem",
              xl: "5.5rem",
            }}
            lineHeight={{
              xs: "2.6rem",
              sm: "3rem",
              md: "3.5rem",
              lg: "5rem",
              xl: "5.5rem",
            }}
            textAlign={{ xs: "center", md: "center" }}
            sx={{ width: "100%", py: "50px", px: "20px" }}
          >
            TenX Account Types
          </Typography>
          {/* <WeOffer /> */}
        </Container>

        <Container maxWidth="md" disableGutters={true}>
          <Box className="Account-Box">
            <div className="Ts-Account-Content">
              <NonmobileUse />

              <div className="TS-Table-row">Spread</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    Floating, starting at 0.1 pips
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">COMMISSION/SPREAD MARKUP</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    No commission, Markup
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">MINIMUM DEPOSIT</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    fontWeight="600"
                    textAlign="center"
                    fontSize="1.5rem"
                  >
                    25 USD
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">INSTRUMENTS</div>
              <div className="TS-Table-data">
                <div>
                  <Box
                    height="200px"
                    backgroundColor="white"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ fontSize: { xs: "1.3rem" } }}
                    >
                      35 currency pairs
                      <br /> + gold and silver
                      <br />+ 3 energies
                      <br />+ 30 cryptocurrencies
                    </Typography>
                  </Box>
                </div>
              </div>
              <div className="TS-Table-row">LEVERAGE</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    sx={{ fontSize: { xs: "1.3rem" } }}
                  >
                    1:500 for currencies
                    <br />
                    (1:100 for ZARJPY)
                    <br />
                    1:200 for metals
                    <br />
                    1:100 for energies
                    <br />
                    1:25 for cryptocurrencies
                    <br />
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">MINIMUM VOLUMNE</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    0.01 lot
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">MAXIMUM VOLUMNE</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    200 lots
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">EXECUTION</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    Market execution in under 0.1 second
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">PRECISION</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    5 digits
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">DEPOSIT CURRENCIES</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    USD
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">MARGIN CALL/STOP OUT LEVEL</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    25% / 15%
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">HEDGING</div>
              <div className="TS-Table-data">
                <div className="TS-Check"></div>
              </div>
              <div className="TS-Table-row">SCALPING</div>
              <div className="TS-Table-data">
                <div className="TS-Check"></div>
              </div>
              <div className="TS-Table-row">SWAPS</div>
              <div className="TS-Table-data">
                <div>
                  <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize="1.2rem"
                  >
                    No swaps
                  </Typography>
                </div>
              </div>
              <div className="TS-Table-row">CFD TRADING</div>
              <div className="TS-Table-data">
                <div className="TS-Check"></div>
              </div>
              <div className="TS-Button">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    height: "50px",
                    width: { sm: "100%", md: "60%", lg: "40%" },
                  }}
                >
                  <Typography
                    color="white"
                    sx={{ textTransform: "none" }}
                    fontWeight="600"
                    fontSize="1.4rem"
                  >
                    Open Account
                  </Typography>
                </Button>
              </div>
            </div>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default HeroBanner;
