import "./Carousel.css";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import { EffectFade, Autoplay } from "swiper";
import { Typography, Container, Button, Box } from "@mui/material";

const Carousel = () => {
  return (
    <div className="Carousel">
      <Container maxWidth="xl" disableGutters={true}>
        <Swiper
          modules={[Autoplay, EffectFade]}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          delay={300}
          effect
          speed={700}
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          loop
        >
          <SwiperSlide className="Carousel-Slide">
            <Box className="Slide Slide1">
              <Box className="S1-1">
                <Typography
                  variant="h1"
                  color="orange"
                  className="H-title"
                  fontSize={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "4rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  lineHeight={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "3.3rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  Trading Made Easy
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  className="Slide-Description"
                  fontSize={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2rem",
                    xl: "2rem",
                  }}
                  sx={{ px: "20px" }}
                  lineHeight={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2.1rem",
                    xl: "2rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  Explore the world of trading made easy with TenX Prime, your
                  trusted online broker.
                </Typography>
                <a
                  href="https://client.tenxprime.com/register"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="home-main-button"
                    sx={{ borderRadius: "10px" }}
                  >
                    <Typography
                      variant="p"
                      color="white"
                      fontWeight="700"
                      className="nav-button"
                      sx={{ textTransform: "none" }}
                    >
                      Trade With Us
                    </Typography>
                  </Button>
                </a>
              </Box>
              <Box className="S1-2"></Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box className="Slide2">
              <div className="S2-2">
                <Typography
                  variant="h1"
                  color="orange"
                  className="H-title"
                  fontSize={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "4rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  lineHeight={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "3.3rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                  sx={{ width: "100%" }}
                >
                  Deposit
                  <br />
                  Bonus
                </Typography>
                <div className="S2-2-Box">
                  <div className="S2-2-B-D">
                    <div className="H-Box-D">
                      <div>
                        <div className="H-chart"></div>
                      </div>
                      <Typography
                        fontSize={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2rem",
                          xl: "2rem",
                        }}
                        lineHeight={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2.1rem",
                          xl: "2rem",
                        }}
                        textAlign="left"
                        color="white"
                      >
                        Trade more with the added bonus
                      </Typography>
                    </div>
                  </div>
                  <div className="S2-2-B-D">
                    <div className="H-Box-D">
                      <div>
                        <div className="H-increase"></div>
                      </div>

                      <Typography
                        fontSize={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2rem",
                          xl: "2rem",
                        }}
                        lineHeight={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2.1rem",
                          xl: "2rem",
                        }}
                        textAlign="left"
                        color="white"
                      >
                        Turn the bonus to profit by trading the required volume
                      </Typography>
                    </div>
                  </div>
                  <div className="S2-2-B-D">
                    <div className="H-Box-D">
                      <div>
                        <div className="H-prohibition"></div>
                      </div>

                      <Typography
                        fontSize={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2rem",
                          xl: "2rem",
                        }}
                        lineHeight={{
                          xs: "1.3rem",
                          sm: "1.8rem",
                          md: "2.1rem",
                          xl: "2rem",
                        }}
                        textAlign="left"
                        color="white"
                      >
                        No limit to bonus claims on every deposit
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="S2-1"></div>
            </Box>
          </SwiperSlide>
          <SwiperSlide className="Carousel-Slide">
            <Box className="Slide Slide3">
              <Box className="S3-1">
                <Typography
                  variant="h1"
                  color="orange"
                  className="H-title"
                  fontSize={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "4rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  lineHeight={{
                    xs: "3rem",
                    sm: "3.5rem",
                    md: "3.3rem",
                    lg: "4.5rem",
                    xl: "4.rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                  sx={{ width: "100%" }}
                >
                  Swap-Free, <br />
                  Stress-Free
                </Typography>
                <Typography
                  variant="h6"
                  color="white"
                  className="Slide-Description"
                  fontSize={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2rem",
                    xl: "2rem",
                  }}
                  lineHeight={{
                    xs: "1.3rem",
                    sm: "1.8rem",
                    md: "2.1rem",
                    xl: "2rem",
                  }}
                  textAlign={{ xs: "center", md: "left" }}
                  sx={{ width: "100%" }}
                >
                  Maximize your profit with no charges and no stress for
                  overnight orders.
                </Typography>
                <Box className="S3-1-button">
                  <a
                    href="https://client.tenxprime.com/register"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className="home-main-button"
                      sx={{ borderRadius: "10px" }}
                    >
                      <Typography
                        variant="p"
                        color="white"
                        fontWeight="700"
                        className="nav-button"
                        sx={{ textTransform: "none" }}
                      >
                        Trade With Us
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Box>
              <Box className="S3-2"></Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
};

export default Carousel;
