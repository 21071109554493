import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: { main: "#00c943" },
    secondary: { main: "#0068ff" },
    tertiary: { main: "#f1a63b" },
  },

  typography: {
    fontSize: 12,
    fontFamily: "'Roboto', sans-serif",

    h1: {
      fontWeight: 900,

      fontSize: {
        xs: "3rem",
        sm: "3.5rem",
        md: "4rem",
        lg: "4.5rem",
        xl: "5rem",
      },
    },
    h2: {
      fontSize: "4rem",
    },
    h3: {
      fontSize: {
        xs: "2.3rem",
        sm: "2.8rem",
        md: "3.2rem",
        lg: "3.8rem",
        xl: "4rem",
      },
    },

    h4: {
      fontSize: "3.0rem",
    },
    h5: {
      fontSize: "2.5rem",
    },
    h6: {
      fontSize: "1.5rem",
    },
    p: {
      fontSize: "1rem",
    },
  },
});
