import "./EcoCalander.css";
import { EconomicCalendar } from "react-tradingview-embed";
import { Container, Box } from "@mui/material";
const EcoCalander = () => {
  return (
    <>
      <div className="marEco-News">
        <Container maxWidth="xl">
          <div>
            <Box sx={{ p: "25px" }}>
              {/* <EconomicCalendarII /> */}
              <EconomicCalendar
                widgetProps={{
                  colorTheme: "light",
                  currencyFilter:
                    "AUD,CAD,EUR,FRF,DEM,ITL,JPY,MYR,MXN,NZD,ZAR,ESP,CHF,TRL,GBP,USD",
                  importanceFilter: "0,1",
                  width: "100%",
                  header: "none",
                }}
              />
            </Box>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EcoCalander;
