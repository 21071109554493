import "./TsAccount.css";
import HeroBanner from "./components/HeroBanner";
import Table from "./components/Table";
import Nav from "../../components/navigation/Nav";
import { Typography } from "@mui/material";
import Footer from "../../components/footer";
const TsAccount = () => {
  return (
    <div className="TsAccount-wrapper">
      <Nav />
      <div className="Empty-spacing"></div>
      <div>
        <HeroBanner />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default TsAccount;
