import "./ThreeReasons.css";
import { Grid, Typography, Box, Container } from "@mui/material";
import NoHidden from "../../resource/NoHidden.svg";
import Reliable from "../../resource/Reliable.svg";
import Negative from "../../resource/Negative.svg";
const ThreeReasons = () => {
  return (
    <>
      <div className="marReason">
        <Container maxWidth="md">
          <Box py="40px">
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="800"
              color="orange"
              fontSize={{ xs: "2.3rem", sm: "2.8rem", md: "3.3rem" }}
            >
              3 Reasons TenX Prime is The Best For Currency Trading
            </Typography>
          </Box>
          {/**Row 1 */}
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={NoHidden}
                    alt="No Hidden Commission"
                    height="260vh"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div className="marReason1">
                  <div className="mR-01">01</div>
                  <Typography
                    color="white"
                    variant="body1"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{
                      pb: "20px",
                      textAlign: { xs: "center", md: "left" },
                      zIndex: 3,
                    }}
                  >
                    No Hidden
                    <br />
                    Commission
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1.2rem"
                    color="white"
                    sx={{ textAlign: { xs: "center", md: "left" }, zIndex: 3 }}
                  >
                    Trade with ease of mind without brokers charging you with
                    hidden commissions. Enjoy our exceptional services focused
                    on your convenience. Your account, your choice.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          {/**Row 2 */}
          <div className="marReason2">
            <Grid
              container
              direction="row-reverse"
              justifyContent="space-around"
              alignItems="stretch"
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={Reliable}
                    alt="Reliable & Innovative Broker"
                    height="280vh"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "right",
                }}
              >
                <div className="marReason2">
                  <div className="mR-02">
                    <p>02</p>
                  </div>

                  <Typography
                    color="white"
                    variant="body1"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{
                      pb: "20px",
                      textAlign: { xs: "center", md: "left" },
                      zIndex: 3,
                    }}
                  >
                    Reliable &
                    <br />
                    Innovative Broker
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1.2rem"
                    color="white"
                    sx={{
                      pb: "20px",
                      textAlign: { xs: "center", md: "right" },
                      zIndex: "10",
                    }}
                  >
                    We adopt timely based on the monitoring of the most
                    intensively executed orders worldwide which allows us to
                    provide you with the best service possible.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          {/**Row 3 */}
          <div className="marReason3">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={Negative}
                    alt="Negative balance protection"
                    height="280vh"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "left",
                }}
              >
                <div className="marReason2">
                  <div className="mR-03">
                    <p>03</p>
                  </div>
                  <Typography
                    color="white"
                    variant="body1"
                    fontWeight="600"
                    fontSize="1.7rem"
                    sx={{
                      pb: "20px",
                      textAlign: { xs: "center", md: "left" },
                      zIndex: 3,
                    }}
                  >
                    Negative Balance
                    <br />
                    Protection
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1.2rem"
                    color="white"
                    sx={{ pb: "20px", textAlign: { xs: "center", md: "left" } }}
                  >
                    You won't owe anything even if your balance goes negative
                    after a bad trading session. TenX will compensate it back to
                    zero.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ThreeReasons;
