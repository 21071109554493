import "./marketEco.css";
import HeroBanner from "./components/HeroBanner";
import EcoCalander from "./components/EcoCalander";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import { Typography } from "@mui/material";
const marketEco = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <EcoCalander />

      <div>
        <Footer />
      </div>
    </>
  );
};

export default marketEco;
