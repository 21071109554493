import "./DepositFunds.css";
import { Grid, Box, Typography, Container } from "@mui/material";
const DepositFunds = () => {
  return (
    <>
      <div className="TSWD-Depo">
        <Container maxWidth="md" sx={{ pb: "10px" }}>
          <Typography
            textAlign="center"
            color="orange"
            variant="h3"
            className="A-Title"
            fontWeight="600"
            sx={{
              fontSize: {
                xs: "3rem",
                sm: "3.3rem",
                md: "3.6rem",
                lg: "3.9rem",
                xl: "4rem",
              },
            }}
          >
            Deposit Funds
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12} md={5}>
              <div className="TSWD-Login"></div>
              <Typography
                sx={{ pt: "20px" }}
                variant="body1"
                textAlign="center"
                color="white"
                fontWeight="500"
                fontSize="1.2rem"
              >
                Log In To The Client Area
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="TSWD-GoTo"></div>
              <Typography
                sx={{ pt: "20px" }}
                variant="body1"
                textAlign="center"
                color="white"
                fontWeight="500"
                fontSize="1.2rem"
              >
                Go to 'New Deposit'
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="TSWD-Selected"></div>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box width="50vh">
                  <Typography
                    sx={{ pt: "20px" }}
                    variant="body1"
                    textAlign="center"
                    color="white"
                    fontWeight="500"
                    fontSize="1.2rem"
                  >
                    Select the trading account you wish to make a deposit to and
                    choose from a number of available payment methods
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default DepositFunds;
