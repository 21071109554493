import { Link } from "react-router-dom";
import "./NonMobile.css";
import logo from "../../../Global/Resource/Assets/Logo-BLUE.svg";
import { Paper, Button, Box, Typography } from "@mui/material";
import Breadcrumb from "../components/Breadcrumb";
const NonMobile = () => {
  return (
    <>
      <nav className="navigation-component-nonM">
        <Paper
          elevation={4}
          className="Nav-Paper"
          sx={{ borderRadius: "0", zIndex: "100" }}
        >
          <Link to="/" className="HomeRouter">
            <div className="Logo">
              <img src={logo} alt="TenX Prime" />
            </div>
          </Link>
          <Box className="Nav-Box">
            <a className="link" href="https://client.tenxprime.com/register">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="nav-main-button"
                sx={{ borderRadius: "10px" }}
              >
                <Typography
                  variant="p"
                  color="white"
                  fontWeight="600"
                  fontSize="1.1rem"
                  className="nav-button"
                  sx={{ textTransform: "none" }}
                >
                  Open a Trading Account
                </Typography>
              </Button>
            </a>
            <a className="link" href="https://client.tenxprime.com/login">
              <Button
                variant="text"
                size="large"
                color="secondary"
                sx={{ borderRadius: "30px" }}
              >
                <Typography
                  variant="p"
                  fontWeight="600"
                  fontSize="1.1rem"
                  color="secondary"
                  className="nav-button"
                  sx={{ textTransform: "none" }}
                >
                  Log In
                </Typography>
              </Button>
            </a>
          </Box>
        </Paper>
        <Paper elevation={6} sx={{ zIndex: 90, borderRadius: "0" }}>
          <Box px="30px">
            <Breadcrumb />
          </Box>
        </Paper>
      </nav>
    </>
  );
};

export default NonMobile;
