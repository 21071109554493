import "./TsDepoWit.css";
import Nav from "../../components/navigation/Nav";
import Footer from "../../components/footer";
import HeroBanner from "./components/HeroBanner";
import PaymentMetods from "./components/PaymentMethods";
import DepositsFunds from "./components/DepositFunds/index";
const TsDepoWit = () => {
  return (
    <>
      <Nav />
      <div className="Empty-spacing"></div>
      <HeroBanner />
      <PaymentMetods />
      <DepositsFunds />
      <div>
        <Footer />
      </div>
    </>
  );
};

export default TsDepoWit;
