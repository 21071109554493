import "./HeroBanner.css";
import {
  Container,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
const HeroBanner = () => {
  const isMobile = useMediaQuery("(max-width:290px)");
  return (
    // <div className="cry-wrap">
    <div className="CC-HeroBanner">
      {/* 
        
      */}

      <Container maxWidth="xl" disableGutters={true}>
        <Box className="cry-banner">
          <Box className="Cry-Titles">
            {!isMobile ? (
              <Typography
                variant="h1"
                color="orange"
                className="H-title"
                fontSize={{
                  xs: "2rem",
                  sm: "3rem",
                  md: "3.3rem",
                  xl: "3.8rem",
                }}
                lineHeight={{
                  xs: "2rem",
                  sm: "3rem",
                  md: "3.3rem",
                  xl: "3.8rem",
                }}
                textAlign={{ xs: "center", md: "center", lg: "left" }}
                sx={{ width: "100%", px: "10px", pt: "30px" }}
              >
                Trade Cryptocurrencies With TenX Prime
              </Typography>
            ) : (
              <Typography
                variant="h1"
                color="orange"
                className="H-title"
                fontSize={{
                  xs: "1.7rem",
                }}
                textAlign={{ xs: "center", md: "center", lg: "left" }}
                sx={{ width: "100%", px: "10px" }}
              >
                Trade Cryptocurrencies With TenX Prime
              </Typography>
            )}
            <Typography
              lineHeight={1.4}
              fontSize="1.4rem"
              color="white"
              sx={{
                paddingX: "20px",
                pb: "20px",
                fontSize: {
                  xs: "1.2rem",
                  sm: "1.7rem",
                  md: "1.7rem",
                  lg: "1.3rem",
                  xl: "1.5rem",
                },
                textAlign: { xs: "center", md: "center", lg: "left" },
              }}
            >
              With up to 30 digital assets for trading, cryptocurrency pairs are
              available on TenX Standard accounts 24/7.
            </Typography>
            <Box className="Cry-Button">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="nav-main-button"
                sx={{ borderRadius: "10px", width: "250px" }}
              >
                <Typography
                  variant="p"
                  color="white"
                  fontWeight="900"
                  fontSize="1.4rem"
                  className="nav-button"
                  sx={{ textTransform: "none" }}
                >
                  Trade Crypto
                </Typography>
              </Button>
            </Box>
          </Box>
          <div className="crybanner"></div>
        </Box>
      </Container>
    </div>
  );
};

export default HeroBanner;
