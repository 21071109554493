import "./Hero.css";
import React from "react";
import "./Hero.css";
import { Typography, Container } from "@mui/material";
import standingPeople from "../..//resource/PeopleStanding4.png";

const Hero = () => {
  return (
    <>
      <div className="AboutWrapper">
        <Container
          maxWidth="xl"
          sx={{ p: "0" }}
          className="aboutBannerContainer"
          disableGutters={true}
        >
          <div className="aboutInnerBanner">
            <div className="TenXHero">
              {/* <img src={standingPeople} alt="happy investors" height="100vh" /> */}
            </div>
            <div className="aboutWhorwe">
              <Typography
                variant="h1"
                color="orange"
                textAlign="center"
                sx={{
                  pt: "20px",
                  pb: "30px",
                  fontSize: {
                    xs: "2.5rem",
                    sm: "3rem",
                    md: "3.5rem",
                    lg: "4rem",
                    xl: "4.5rem",
                  },
                }}
              >
                Who Are We?
              </Typography>
              <Typography
                lineHeight={1.4}
                variant="h3"
                fontSize="1.4rem"
                color="white"
                sx={{
                  paddingX: "20px",
                  pb: "20px",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.7rem",
                    md: "2rem",
                    lg: "1.3rem",
                    xl: "1.5rem",
                  },
                  textAlign: { xs: "center", md: "center", lg: "left" },
                }}
              >
                TenX Prime Limited promises to deliver a powerful,
                user-friendly, and fair trading platform. As an industry leader,
                our goal is to equip you with all the tools you will need to
                understand the mechanics of economic events, hence allowing you
                to fully maximize your investments. Our strategies and
                approaches will develop along with the ever changing financial
                market, putting your needs as a trader at the forefront with
                every decision that we make.
              </Typography>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Hero;
