import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Card, Divider } from "@mui/material";
import { useState } from "react";

const SimpleAccordion = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  return (
    <div>
      <Box
        display="flex"
        alignItems="flex-start"
        flexWrap="wrap"
        justifyContent="space-around"
        gap={2}
        pb="20px"
      >
        <Card
          onMouseOver={() => setExpanded1(true)}
          onMouseLeave={() => setExpanded1(false)}
          onTouchStart={() => setExpanded1(true)}
          onTouchEnd={() => setExpanded1(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: "250px" }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded1}
          >
            <AccordionSummary
              id="pannel1-header"
              aria-controls="panel1a-content"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="800">
                  01
                </Typography>
                <Typography fontWeight="600" fontSize="1.6rem">
                  A major growing trend in recent history.
                </Typography>
                <Box sx={{ width: "30%" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Typography fontWeight="500" fontSize="1.2rem">
                In 2020, Bitcoin's price spiked to a whopping 836%.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded2(true)}
          onMouseLeave={() => setExpanded2(false)}
          onTouchStart={() => setExpanded2(true)}
          onTouchEnd={() => setExpanded2(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: "250px" }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded2}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel2-header"
            >
              <Box
                sx={{
                  height: "300px",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="800">
                  02
                </Typography>
                <Typography fontWeight="600" fontSize="1.6rem">
                  Simple crypto-currency fundamentals.
                </Typography>
                <Box sx={{ width: "30%" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontFamily="500" fontSize="1.2rem">
                Get up and running without the hassle of having an e-wallet or
                looking for a better exchange rate.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded3(true)}
          onMouseLeave={() => setExpanded3(false)}
          onTouchStart={() => setExpanded3(true)}
          onTouchEnd={() => setExpanded3(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: "250px" }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded3}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel3-header"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="800">
                  03
                </Typography>
                <Typography fontWeight="600" fontSize="1.6rem">
                  Extremely volatile.
                </Typography>
                <Box sx={{ width: "30%" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontFamily="500" fontSize="1.2rem">
                Sensational performance from bitcoin changed the landscape of
                the cryptocurrency world as trading volumes grow.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
        <Card
          onMouseOver={() => setExpanded4(true)}
          onMouseLeave={() => setExpanded4(false)}
          onTouchStart={() => setExpanded4(true)}
          onTouchEnd={() => setExpanded4(false)}
          elevation={6}
          sx={{ borderRadius: "10px", width: "250px" }}
        >
          <Accordion
            sx={{ borderRadius: "10px", minHeight: "320px" }}
            expanded={expanded4}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="pannel4-header"
            >
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="orange" fontSize="3rem" fontWeight="800">
                  04
                </Typography>
                <Typography fontWeight="600" fontSize="1.6rem">
                  An established, trusted broker.
                </Typography>
                <Box sx={{ width: "30%" }}>
                  <Divider color="orange" sx={{ pb: "0.3rem" }} />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontFamily="500" fontSize="1.2rem">
                All you have to do is just 'Plug & Play' on your MetaTrader
                platform with TenX Prime.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Box>
    </div>
  );
};

export default SimpleAccordion;
